#contrato-revisao {
  .titulo-secao {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .titulo-item {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #6a7c96;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .subtitulo-secao {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #344453;
  }

  .container-titulo-secao {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .container-subtitulo {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .container-item {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .row-revisao2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;
    padding-bottom: 40px;
    background-color: white;
  }

  .box-revisao2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 24px 10px 24px;
    height: auto;
    width: 100%;
    background-color: white;
  }

  .dados {
    position: relative;
    width: 100%;
  }

  .dados button {
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 73px;
    height: 40px;
    border: 1.5px solid #ebeffc;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #895cf2;
    background-color: transparent;
  }

  .valor-item {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #485d73;
  }
}
