.box-msg-servico {
	position: absolute;
	max-width: 500px;
	padding: 20px;
	color: #fff;
	background: rgba(0, 0, 0, 0.85);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
	transition: 1s ease-in-out;
	z-index: 1;
}

.box-msg-servico p {
	font-size: 0.9em;
}

.box-msg-servico li {
	font-size: 0.9em;
}

.box-msg-servico h1 {
	color: #fff;
	border-bottom: 2px solid #fff;
}

.box-msg-servico h2 {
	color: #fff;
	border-bottom: 2px solid #fff;
}

.box-msg-servico img {
	background-color: #fff;
	border-radius: 100%;
	margin-right: 10px;
}