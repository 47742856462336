.report-powerbi__title-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title-content__title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;

	h1 {
		font-weight: bold;
		font-size: 26px;
		line-height: 48px;
		color: #353f49;
	}
}

.arrow-button {
	background-color: transparent;

	box-shadow: none;

	&:hover {
		background-color: transparent;
		box-shadow: none;
	}
}

.title-content__buttons-div {
	display: flex;
	align-items: center;
	gap: 16px;

	button {
		gap: 8px !important;

		height: 40px !important;
	}
}
