.tag-container-sede{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(127,211,143,.2);
  border-radius: 500px;
}

.tag-container-sede span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #478953;
  margin-left: 5px;
}

.tag-container-admin{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(110, 194, 201, 0.2);
  border-radius: 500px;
}

.tag-container-admin span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #306F75;
  margin-left: 5px;
}

.tag-container-colaborador{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(255,151,91,.2);
  border-radius: 500px;
}

.tag-container-colaborador span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #cf6d34;
  margin-left: 5px;
}

.tag-container-franquia{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(207, 107, 108, 0.2);
  border-radius: 500px;
}

.tag-container-franquia span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #AA5556 !important;
  margin-left: 5px !important;
}

.tag-container-instrutor{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(109, 180, 248, 0.2);
  border-radius: 500px;
}

.tag-container-instrutor span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3771A8;
  margin-left: 5px;
}

.tag-container-gestor{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(184, 154, 253, 0.2);
  border-radius: 500px;
}

.tag-container-gestor span{
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #895CF2 !important;
  margin-left: 5px !important;
}

#min-tag{
  width: auto !important;
  max-width: 50% !important;
  margin-top: 15px;
}

.tag-container-comercial{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(250, 138, 139, 0.2);
  border-radius: 500px;
}

.tag-container-comercial span{
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #CF6B6C !important;
  margin-left: 5px !important;
}

.tag-games{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(243, 186, 39, 0.2);
  border-radius: 500px;
}

.tag-games span{
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #F3BA27 !important;
  margin-left: 0px !important;
}

.tag-container-comercial{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: rgba(250, 138, 139, 0.2);
  border-radius: 500px;
}

.tag-container-comercial span{
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #CF6B6C !important;
  margin-left: 5px !important;
}

.tag-youtube{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 8px 16px;
  background: #FACBCB;
  border-radius: 500px;
}

.tag-youtube span{
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #F56B6C !important;
  margin-left: 5px !important;
}
