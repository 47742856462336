.line {
  display: flex !important;
  flex-direction: row !important;
}

.col {
  display: flex !important;
  flex-direction: column !important;
}

.flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  display: flex !important;
  align-items: center !important;
} 

.flex-space-between {
  display: flex !important;
  justify-content: space-between !important;
}

.flex-space-around {
  display: flex !important;
  justify-content: space-around !important;
}