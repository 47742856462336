.modal__licenca__hub {
    width: 800px !important;
    max-width: 800px;
}

.modal__licenca__hub .ant-modal-header {
    background-color: var(--blue-dark);
}

.modal__licenca__hub .ant-modal-title {
    font-weight: bold;
    color: #fff;
}

.modal__licenca__hub .ant-modal-footer {
    background-color: var(--blue-dark);
}

.modal__licenca__hub .ant-modal-footer button {
    background-color: #fff;
    color: var(--blue-dark);
}

.modal__licenca__hub .ant-modal-footer button:hover {
    background-color: var(--blue-dark);
    color: #fff;
    box-shadow: #fff 0px 0px 0px 2px;
}

.modal__licenca__hub .ant-modal-close-x {
    color: #fff;
}

.modal__licenca__hub .ant-modal-close-x:hover {
    color: #fff;
}

.modal__licenca__hub header {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
    border-bottom: 2px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.modal__licenca__hub header h1 {
    font-size: 24px;
    color: #333;
    margin: 0;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}