.seta {
	display: block;
	position: relative;
	height: 1px;
	width: 100px;
	background-color: #000;
}

.seta::after {
	content: '';
	width: 10px;
	height: 10px;
	box-sizing: border-box;
	border: 0 solid #000;
	border-top-width: 1px;
	border-right-width: 1px;
	position: absolute;
	top: -5px;
	right: 2px;
	transform: rotate(45deg);
}