.box-voltar-tela {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 40px;
    border-radius: 100%;
    color: #fff;
    background-color: #0c306c;
}

.box-voltar-tela:hover {
    width: 100px !important;
    height: 35px !important;
    padding: 22px;
    border-radius: 5%;
    color: #fff;
    background-color: #0c306c;
}