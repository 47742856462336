#botao-avancar-cadastro-crm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  background: #ebeffc;
  border-radius: 4px;
  margin-left: 10px;
  color: #92A9CB;
}

#botao-avancar-cadastro-crm-ativo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  background: #1A4DA1;
  border-radius: 4px;
  margin-left: 10px;
  color: #FFF;
}


#botao-cancelar-cadastro-crm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid #92A9CB;
  color: #1A4DA1
}

#contaner-botao-cadastro {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}