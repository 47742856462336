.dot-cadastro-lead {
  color: #eceff1;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.dot-cadastro-lead-ativo {
  color: #1a4da1;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.step-cadastro-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #6a7c96;
}
