
/* fonts */  
@font-face {
    font-family: 'KobelBold1-1';
    src: url('../../assets/fonts/Kobe1.1-Bold.otf');
}
@font-face {
    font-family: 'KobelBlack1-1';
    src: url('../../assets/fonts/Kobe1.1-Black.otf');
}
@font-face {
    font-family: 'Roboto-Black';
    src: url('../../assets/fonts/Roboto-Black.ttf');
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../assets/fonts/Roboto-Regular.ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


/* /fonts */
.body{
    width: 100%;
    display: flex;   
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    position: fixed;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 7px; 
    }
    ::-webkit-scrollbar-thumb {
        background-color: #0C306C; 
        border-radius: 5px; 
    }
    
 /* header */
    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        background-color:#0C306C;
        min-height: 125px;
    }
.header_box{
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
     h1{
        font-family: 'KobelBlack1-1', sans-serif;
        color: #fff;
        font-size: 2.5rem;
     }
     div{
        transform: translateY(6px);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
     }
    }
}
.icons_header{
    transform: translateY(-5px);
    width: 110px;
    height: 24px;
}
 /* /header */

/* body midle */
 .body_section{
    margin-bottom: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    h2{
        font-size: 1.85rem;
        color: #0C306C;
    }
    h3{
        font-size: 1.35rem;
        color: #0C306C;
    }
    h4{ 
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 1.10rem;
        color: #0C306C;
    }
    h5{
        font-family: 'Roboto-Black', sans-serif;
        font-size: 0.85rem;
        color: #0C306C;
        font-weight: bold;
    }
    h6{
        font-size: 0.6rem;
        color: #0C306C;
    }
    p{
        color: #0C306C;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 0.95rem;
    }
    .transparent{
        opacity: 0.5;
    }
    .span_header{
        font-size: 0.95rem;
        color:#0c2f6c73;
        font-family: 'Roboto Condensed', sans-serif;
        

    }

}
.columnOne_header{
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    min-height: 45px;
    width: 100%;
    border-bottom: 1px solid #0c2f6c73;    
}
.columnTwo_header{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 45px;
    width: 100%;
    border-bottom: 1px solid #0c2f6c73;   
}
.arrow{
    transform: rotate(270deg);
    position: absolute;
    bottom: 15px;
    cursor: pointer;
    transition: 0.3s ease all;
}
.columnOne, .columnTwo{
    background-color: #FFF;
    padding: 20px;
    box-shadow: 1px 1px 5px 0px #0c2f6c73;
}
.columnOne{
    min-width: 45%;
    max-width: 45%;
    max-height: 90vh;
    min-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.columnTwo{
    min-width: 38%;
    max-width: 38%;
    border-left: 1px solid #0c2f6c73;
}
.containerMiddle{
    gap: 5px;
    width: 100%;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    text-align: left;
    div{
        margin-left: 0.1rem;
        height: 100%;
    }
    
}

/* / body */

/* step-1 */
.closed{
    height: 0px;
    overflow: hidden;
}

.step_1{
    width: 100%;
    transition: all 0.5s ease;
}
.step_2{
    width: 100%;
    transition: all 0.5s ease;
}
.step_3{
    width: 100%;
    transition: all 0.5s ease;
}
.step_1_header{
    margin-top: 1rem;
    overflow: hidden;
    div span{
        font-size: 0.75rem; 
        font-family: 'Roboto Condensed', sans-serif;

    }
}
.table{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 30px;
}
.table_body{
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
}
.table_body p{
    font-size:0.90rem;
    color: #0C306C;
    font-family: 'Roboto Condensed', sans-serif;
}
.rowClassCheckout{
    font-size:0.90rem;
    color: #0C306C;
    font-weight: 500;
    border: none;
    cursor: auto;
}
.tableCheckout{
    thead > tr > th {
        color: #0c2f6c73;
        border: none;
        text-align: center;
        font-family: 'Roboto-Regular', sans-serif;

    }
    td{
        border: none;
        text-align: center;
        cursor: initial;
        font-family: 'Roboto-Regular', sans-serif;
        font-weight: 300;
    }
}
.btn_step1{
    background: #0C306C;
	border-radius: 4px !important;
	color: #ffffff !important;
	font-family: 'KobelBold1-1', 'sans-serif' !important;
    font-size: 1.10rem;
	line-height: 24px !important;
	text-align: center !important;
	height: 40px !important;
	max-width: 100% !important;
	border: 0px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
}
/* /step-1 */

/* step-2 */

.step_2_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .creditCard{
        width: 210px;
        height: 25px;
    }
}
.box_payment_radio{
    color: #0C306C;
    font-size: 0.85rem;
    font-family: 'Roboto Condensed', sans-serif;
    min-width: 160px;
    max-width: 160px;
    height: 40px;    
    margin-bottom: 0.5rem;
}
.radioGrup{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    overflow: hidden;
    margin-top: 1rem;
}
.box_pay{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;    
}
.botoes{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.btn_voltar{
    width: 40%;
    color: #0C306C;
    border-radius: 4px;
    background: #EBEBEB; 
    font-family: 'KobelBold1-1', 'sans-serif'; 
    height: 40px;
}
.btn_proximo{
    width: 40%;
    background: #0C306C;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'KobelBold1-1', 'sans-serif';
    height: 40px;
}
.form_general{
    border: 1px solid #0c2f6c73;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
}
.input{
    font-size: 1.05rem;
    color:#0C306C;
    font-family: 'Roboto Condensed', sans-serif;
}
.box_credit_card{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
}
small{
    color: #0C306C;
    font-size: 0.75rem;
}
.invisible_container{
    width: 100%;
    height: 180px;
    background-color: transparent;
}
/* /step-2 */

/* step-3 */
.container_rev{
    gap: 1.5rem;
    margin: 1.5rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.first_line{
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    div{
        min-width: 100px;
        text-align: left;
        margin-left: 0.1rem;
    }
}
.second_line{
    display: flex;
    div{
        min-width: 100px;
        text-align: left;
        margin-left: 0.1rem;
    }
}
.btn_step3{
    background: #0C306C;
    border-radius: 4px !important;
    color: #ffffff !important;
    font-family: 'KobelBold1-1', 'sans-serif' !important;
    font-size: 1.10rem;    
    line-height: 24px !important;
    text-align: center !important;
    height: 40px !important;
    max-width: 100% !important;
    border: 0px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.container_captcha{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn_step3_voltar{
    background: #EBEBEB;
    border-radius: 4px !important;
    color: #0C306C !important;
    font-family: 'KobelBold1-1', 'sans-serif' !important;
    font-size: 1.10rem;    
    line-height: 24px !important;
    text-align: center !important;
    height: 40px !important;
    max-width: 100% !important;
    border: 0px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
/* /step-3 */

/* responsividade */

@media screen and (max-width: 1600px){
    .invisible_container{
        width: 100%;
        height: 160px;
        background-color: transparent;
    }
}
@media screen and (max-width: 980px){
  
    .rowClassCheckout{
        background-color: #F5F5F9;
    }
    .header{
        min-height: 80px;
    }
    .header .header_box h1 {
        font-size: 2rem; 
        }  
    .icons_header{
            width: 80px;
            height: 15px;
            margin: 0.1rem;
        }
    .header_box{
        height: 50%;
    }
    .body_section{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
     /*   overflow-x: hidden; */

    }
   .body_section > .columnOne  {
         min-width: 90%;
         max-width: 90%;
         background-color: transparent;
         box-shadow: none;
    }
    .body_section > .columnTwo {
        min-width: 90%;
        max-width: 90%;
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
    .first_line{
        flex-direction: column;
        gap: 25px;
    }
    .first_line > div{
        text-align: left;
    }
    .second_line > div{
        text-align: left;
    }
    .radioGrup{
        width: 50%;
        margin-top: 1rem;
    }
    .step_2_header{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }
    .botoes{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    .botoes button{
        width: 100%;
    }
    .invisible_container{
        width: 100%;
        height: 15px;
        background-color: transparent;
    }
    .body{
        position: inherit;
    }
    .input{
        background-color: transparent;
    }
}

:disabled{
    opacity: 0.8;
    cursor: not-allowed;
}