.box-relatorio {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 1;
	row-gap: 4px;

	background: var(--white-color);

	min-height: 150px;
	max-height: auto;

	border-radius: 8px;
	border: 1px solid #edeff4;

	cursor: pointer;

	padding: 16px;

	&:hover {
		border: 1px solid #c5cddc;
	}
}

@media (min-width: 640px) {
	.box-relatorio {
		padding: 24px;
	}
}

.box-relatorio__texto {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;

	text-align: center;

	color: #344453;
}
