.menu-sidebar-and-container__children-wrapper:has(> .content-all){
        border: 0px !important;
        padding: 0px !important;
}

.content-all{
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../../../src/assets/img/franquias/fala-comandante.png) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff !important;
}

.content-box {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    .content {
        display: flex;
        justify-content: space-around;
        height: 100%;
        width: 100%;
    }
}

.introduction {
    display: flex;
    gap: 10px;
    flex-direction: column;

    h1 {
        font-family: 'KobelBlack1-1', sans-serif;
        font-weight: 900;
        font-size: 70px;
        line-height: 74.55px;
        color: #fff;
    }

    .user {
        font-size: 55px;
        font-weight: 300px;
    }

    p {
        font-family: 'Roboto-Black';
        font-weight: 400;
        font-size: 20px;
        line-height: 23.44px;
        width: 643px;

        b {
            font-weight: 700;
        }
    }

    h2 {
        font-family: 'Roboto-Black';
        font-weight: 700;
        font-size: 20px;
        line-height: 55px;
        color: #fff;
    }
}

.form-comander {
    width: 500px;
    display: flex;
    flex-direction: column;

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        label {
            font-weight: 500;
            font-family: 'Roboto-Black';
            font-size: 20px;
            line-height: 30px;
        }

        .select-box {
            display: flex;
            flex-direction: column;

            .select-wrapper {
                display: flex;
                justify-content: flex-end;

                select {
                    cursor: pointer;
                    padding: 10px 0;
                    width: 100%;
                    background-color: #fff;
                    color: #000;
                }

                .custom-arrow {
                    position: absolute;
                    pointer-events: none;
                    padding: 10px;
                }
            }
        }

        .coment-box {
            .text-area {
                height: 120px;
            }
        }

        .button {
            display: flex;
            justify-content: center;

            :hover {
                span {
                    color: #000
                }
            }
        }
    }
}