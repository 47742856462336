.modalContainer {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalBox {
	display: flex;
	flex-direction: column;
	/* align-items: flex-start; */
	align-items: center;
	padding: 16px;
	position: relative;
	width: 776px;
	height: auto;
	background: #ffffff;
	box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
	border-radius: 8px;
}

.modalBox h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
}

.modalBox h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
	margin-bottom: 24px;
}

.modalBox .inputModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8px 12px;
	width: 100%;
	height: 58px;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 24px;
}

.modalBox .inputModal span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

.modalBox .vermelho {
	color: #ff0000 !important;
}

.modalBox .inputModal input {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
	width: 100%;
}

.modalBox .botoes {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.modalBox .botoes .btn-cancel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 146px;
	height: 40px;
	background-color: transparent;
	border: 1.5px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 4px;
	color: #895cf2;
	margin-right: 16px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}

.modalBox .botoes .btn-make {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 146px;
	height: 40px;
	background: #895cf2;
	border-radius: 4px;
	color: #ffffff;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}

.modalBox .fechar {
	position: absolute;
	right: 16px;
	top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: #ebeffc;
	border-radius: 8px;
}
