.turma-detalhes-wrapper__title-back-button {
  display: flex;
  align-items: center;
  gap: 16px;
}

.turma-info-and-turma-progress {
  display: flex;
  gap: 40px;
}

.turma-info {
  padding: 16px;

  border-radius: 8px;

  background-color: var(--white);

  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 70%;
}

.turma-info__turma-title-duration {
  display: flex;
  flex-direction: column;
  gap: 4px;

  h5 {
    font-size: 1.3rem;
    line-height: 36px;
    color: #344453;
  }

  small {
    font-size: 12px;
    line-height: 18px;
    color: #6a7c96;
  }
}

.turma-title-duration__duration-status {
  display: flex;
  align-items: center;
  gap: 16px;

  small {
    font-size: 14px;
    line-height: 20px;
    color: #6a7c96;
  }
}

.box-detalhe-turma-dot {
  display: flex;
  align-items: center;
}

.dot-detalhe-turma-verde,
.dot-detalhe-turma-vermelho,
.dot-detalhe-turma-standby {
  width: 8px;
  height: 8px;

  border-radius: 4px;

  margin-right: 5px;
}

.dot-detalhe-turma-verde {
  background-color: #1ebe5e;
}

.dot-detalhe-turma-vermelho {
  background-color: #ff4d4f;
}

.dot-detalhe-turma-standby {
  background-color: #F59300;
}

.detalhe-turma-p-verde,
.detalhe-turma-p-vermelho,
.detalhe-turma-p-standby {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  margin-bottom: 0;
}

.detalhe-turma-p-verde {
  color: #1ebe5e;
}

.detalhe-turma-p-vermelho {
  color: #ff4d4f;
}

.detalhe-turma-p-standby {
  color: #F59300;
}

.turma-info__alunos-ativos-minutos {
  display: flex;
  align-items: center;
  gap: 24px;
}

.alunos-ativos-minutos-container {
  display: flex;
  align-items: center;
  gap: 4px;


  p {
    font-size: 14px;
    line-height: 20px;
    color: #6a7c96;
  }
}

.turma-info__horarios-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  small {
    font-size: 12px;
    line-height: 18px;
    color: #6a7c96;
  }
}

.horarios-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.horarios-container__horario-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 35px;
  width: 130px;

  background: var(--blue-color-20);

  border-radius: 4px;

  padding: 8px;

}

.horario-box__dia-semana {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--primary-color);
}

.horario-box__horario {
  font-size: 12px;
  line-height: 18px;
  color: var(--blue-ligth);
}

.turma-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  border-radius: 8px;

  background-color: var(--white);

  padding: 16px;

  width: 30%;

  &__progress-text {
    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #344453;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #485D73;
    }
  }
}