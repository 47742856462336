.layout-checkout {
	width: 100%;
	overflow: scroll;
	height: calc(100vh - 1px);
}

.content-body {
	width: 1012px;
	margin: 0px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.content-center {
	background-color: #ffffff;
	/* margin: 0px auto; */
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content-left {
	background-color: #ffffff;
	width: 59%;
	padding: 15px;
	border-radius: 10px;
}

.content-left h2 {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	margin-top: 30px;
}

#box-pedido {
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	height: auto;
	margin-bottom: 5px;
}

#box-pedido .icon-pedido {
	position: absolute;
	right: 10px;
	top: 10px;
}

.content-left p.parrafo-pedido {
	font-size: 10px;
	margin-bottom: 20px;
}

.content-left h3 {
	font-size: 14px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	margin-top: 10px;
}

.content-left p {
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	margin-bottom: 0px;
}

.content-left span {
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.content-left .catpcha {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 20px auto;
	padding: 10px;
	width: 100%;
	height: 100px;
	border: 1px solid #eeeeee;
	border-radius: 10px;
}

#botoes-pedido {
	justify-content: flex-end;
}

.content-right {
	background-color: #ffffff;
	width: 39%;
	padding: 15px;
	border-radius: 10px;
}

.content-right h2 {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	margin-top: 10px;
}

.content-right .pedido,
.content-right .pedido-total {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.content-right .pedido-total {
	margin-top: 15px;
}

.content-right .pedido p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 24px;
	color: #111111;
	display: flex;
	flex-direction: row;
}

.content-right .pedido .quan {
	width: 20px;
	height: 20px;
	background-color: #d5dbe1;
	border-radius: 5px;
	color: var(--primary-color);
	margin-left: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.content-right .pedido span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #111111;
}

.content-right .pedido-total p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #111111;
	margin-bottom: 0px;
}

.content-right .valores p {font-weight: 100}

.content-right .pedido-total span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #111111;
}

.content-right .valores span {font-weight: 100}

.items-pedido {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 910px) {
	.content-body {
		width: 90%;
		flex-direction: column;
		justify-content: flex-start;
	}
	.content-left {
		width: 100%;
		margin-bottom: 20px;
	}
	.content-right {
		width: 100%;
	}
}

/* Pagamento */

.radio-pedido {
	margin: 30px auto;
	width: 100%;
}

.radio-opcao {
	width: 100%;
	padding: 10px;
	border: 1px solid #eeeeee;
	border-radius: 15px;
	cursor: pointer;
}

.justify {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.container-aviso-recorrencia-ativa {
	background-color: #002c6e;
	display: flex;
	flex: 1;
	justify-content: center;
}

.texto-aviso-recorrencia-ativa {
	font-size: 28px;
	color: white;
	font-family: Open Sans;
	font-weight: bold;
	padding: 20px 0px 20px 0px;
}
