.box-title #turma h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #92A9CB;
  margin-bottom: 0px;
}

.box-title #turma h4{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #344453; 
  margin-bottom: 0px; 
}

.spanFinanceiro{
  color: #6a7c96;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin-right: 5px;
}

.boxes-financeiro{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.boxes-financeiro .box-financeiro{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 35px;

}

.boxes-financeiro .box-financeiro .icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: rgba(30, 190, 94, 0.2);
  border-radius: 8px;
  margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-aberto{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: rgba(255, 151, 91, 0.2);
  border-radius: 8px;
  margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-vencido{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: rgba(255, 77, 79, 0.2);
  border-radius: 8px;
  margin-right: 15px;
}

.boxes-financeiro .box-financeiro .pago h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #344453;  
  margin-bottom: 0px;
}

.boxes-financeiro .box-financeiro .pago h4{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #92A9CB;
  margin-bottom: 0px;
}

.tabela .registro-user#registro-financeiro{
  padding-top: 17px;
  margin-bottom: 0px;
}

.tabela .registro-user#registro-financeiro:hover {
  background-color:rgba(203, 212, 237, 0.2);
  padding-top: 17px;
  margin-bottom: 0px;
}

.tabela .registro-user#registro-financeiro-selected{
  padding-top: 17px;
  margin-bottom: 0px;
  background-color:rgba(184, 154, 253, 0.2);
}

.check-left{
  margin-left: 10px;
}

.filtros-botoes-financeiro{
  display: none;
}

.filtros-botoes-financeiro-active{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.config-icons-financeiro{
  width: 20px;
  margin-left: 2px;
  cursor: pointer;
}

.config-alert-financeiro{
  position: absolute;
  z-index: 1;
}

.filtro-estado-financeiro{
  width: 200px;
} 

/********************************| <Select> Configuração do dropdown para exibir todas as options na lista |********************************/
.dropdown-antd {
	width: max-content !important;
}

#input-dashboard{
  min-width: max-content !important;
  max-width: 0 !important;
}

/***************************************************************| <Select/> |**************************************************************/

/********************************| Responsividade min: 1200px / max: 1400px |********************************/
@media only screen and (min-width: 1300px) and (max-width: 1400px) {

  .responsividade-pesquisa{
    width: 90% !important;
  }

  #input-dashboard{
    min-width: max-content !important;
    max-width: 0 !important;
  }
}

/********************************| Responsividade min: 600px / max: 1199px |********************************/
@media only screen and (min-width: 600px) and (max-width: 1299px) {

  .ant-select-selection-item{
    padding: 0 15px 0 0 !important;
  }

  #input-dashboard{
    min-width: max-content !important;
    max-width: 0 !important;
  }
  
  .responsividade-pesquisa{
    position: relative !important;
    display: block !important;
    justify-content: center !important;
    align-items: center !important;
    width: 90% !important;
    margin: 10px 10px 10px 0 !important;
  }

  .responsividade-calendario{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 10px 10px 0 !important;
  }

  .responsividade-tipo{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 20px 10px 0 !important;
  }

  .responsividade-status{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    width: max-content !important;
    margin: 10px 0 10px 0 !important;
  }
}

/********************************| Responsividade min: 100px / max: 599px |********************************/
@media only screen and (min-width: 100px) and (max-width: 599px) {


  .ant-select-selection-item{
    padding: 0 15px 0 0 !important;
  }

  #input-dashboard{
    min-width: max-content !important;
    max-width: 0 !important;
  }
  
  .responsividade-pesquisa{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    width: 85% !important;
    margin: 10px 10px 10px 10px !important;
  }

  .responsividade-calendario{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    width: 360px !important;
    margin: 10px 10px 10px 0 !important;
  }

  .responsividade-tipo{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 20px 10px 0 !important;
  }

  .responsividade-status{
    position: relative !important;
    display: inline-block !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 10px 0 10px 0 !important;
  }
}