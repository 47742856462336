.box-offer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 56px;

  border-radius: 4px;
  color: white;

  img {
    width: auto;
    height: 16px;
  }
}

.box-offer:disabled {
  background-color: rgba(0, 0, 0, 0.1);

  cursor: not-allowed;
}

.box-offer-code {
  background-color: rgba(176, 203, 81, 0.3);
}

.box-offer-code-selected {
  background-color: rgba(176, 203, 81, 1);
}

.box-offer-money {
  background-color: rgba(251, 175, 31, 0.3);
}

.box-offer-money-selected {
  background-color: rgba(251, 175, 31, 1);
}

.box-offer-speech {
  background-color: rgba(220, 55, 53, 0.3);
}

.box-offer-speech-selected {
  background-color: rgba(220, 55, 53, 1);
}

.box-offer-english {
  background-color: rgba(244, 118, 46, 0.3);
}

.box-offer-english-selected {
  background-color: rgba(244, 118, 46, 1);
}

.box-offer-curta {
  background-color: rgba(131, 226, 234, 0.3);
}

.box-offer-curta-selected {
  background-color: rgba(131, 226, 234, 1);
}

.box-offer-colonia {
  background-color: rgba(103, 58, 183, 0.3);
}

.box-offer-colonia-selected {
  background-color: rgba(103, 58, 183, 1);
}

.box-offer-escolas {
  background-color: rgba(103, 58, 183, 0.3);
}

.box-offer-escolas-selected {
  background-color: rgba(103, 58, 183, 1);
}

.box-offer-antigo {
  background-color: rgba(103, 58, 183, 0.3);
}

.box-offer-antigo-selected {
  background-color: rgba(103, 58, 183, 1);
}
