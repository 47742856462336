.progress{
  height: 4px;
  width: 100%;
  background-color: rgba(127, 211, 143, 0.4) !important;
  position: fixed;
  top:86px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
}

.progress .step{
  height: 100%;
  width: 16%;
  background-color: #7FD38F;
}