#cursos {
	width: 32%;
}

#cursos-id {
	width: 10%;
}

#cursos-modalidade {
	width: 15%;
}

#user-franquias-revisao h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
	margin-bottom: 5px;
}

#user-franquias-revisao p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: var(--primary-color);
	margin-bottom: 15px;
}

.tabela .registro-user .user .nome .estado-user .dot-cinza {
	width: 8px;
	height: 8px;
	background-color: rgba(52, 68, 83, 0.5);
	border-radius: 8px;
	margin-right: 7px;
}

.tabela .registro-user .user .nome .estado-user p.suspenso {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: rgba(52, 68, 83, 0.5);
}

#cursos-boxes {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 25px;
	width: 100%;
}

#cursos-boxes #curso-box {
	margin-right: 30px;
}

#cursos-boxes #curso-box input {
	height: 40px !important;
	font-family: Open Sans !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: rgba(52, 68, 83, 0.5) !important;
	padding-left: 15px !important;
}

#boxes-radios-cursos,
#box-aplicacao {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 15px;
}

#boxes-radios-cursos {
	width: 49%;
}

#box-radio-selected {
	max-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 16px;
	height: 56px;
	background: var(--primary-color);
	border-radius: 8px;
}

#box-radio-selected span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 0px;
}

#check {
	position: absolute !important;
	left: 16px !important;
}

#box-radio {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 16px;
	max-width: 100%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 8px;
}

#box-radio span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: rgba(52, 68, 83, 0.5);
	margin-bottom: 0px;
}

#box-aplicacao #box-selected {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	max-width: 100%;
	height: 96px;
	background: var(--primary-color);
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 10px;
}

#box-aplicacao #box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	max-width: 100%;
	height: 96px;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 10px;
}

#box-aplicacao #box p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 32px;
	text-align: center;
	color: #344453;
	margin-bottom: 0px;
}

#box-aplicacao #box-selected p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 32px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 0px;
}

h6#subtitle-curso {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #6a7c96;
}

#cursos-info {
	justify-content: space-between;
}

.cpf#cursos-info {
	width: 48%;
	margin-right: 0px;
}

#input-curso {
	width: 17%;
	min-width: 185px;
	max-width: 200px;
}

#cursos-check-dash {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.space-align-block {
	flex: none;
	margin: 8px 4px;
	padding: 3px;
	/* border: 1px solid #40a9ff; */
}


.box-alternativa {
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 10px;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

