.course-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;

  cursor: pointer;

  width: 100%;

  border-radius: 8px;

  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #cbd4ed33;
  }
}

.course-card-wrapper__course-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.course-infos__classroom-text {
  color: #454545;
  font-size: 12px;
  line-height: 20px;
}
.course-infos__classroom-text-small {
  @extend .course-infos__classroom-text;
  font-size: 13px;
}

.course-infos__financeiro-code {
  display: flex;
  gap: 32px;

  p {
    color: #313131;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 !important;
  }
}

.course-card-wrapper__upgrade-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 12px;

  height: 32px;

  border: 1.5px solid #ebeffc;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
}