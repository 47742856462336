#head-turma {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 30px;
}

.matriculas-curso .turmas {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  gap:10px;
}

.matriculas-curso .turmas .turma-selected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 98%;
  height: auto;
  background: #895cf2;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.matriculas-curso .turmas .turma-selected .mais {
  position: absolute;
  right: 20px;
  top: 15px;
}

.matriculas-curso .turmas .turma-selected h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma-selected h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.matriculas-curso .turmas .turma-selected .status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.matriculas-curso .turmas .turma-selected .status .dot-green {
  width: 8px;
  height: 8px;
  background: #1ebe5e;
  border-radius: 4px;
  margin-right: 5px;
}

.matriculas-curso .turmas .turma-selected .status p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1ebe5e;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma-selected .dados {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.matriculas-curso .turmas .turma-selected .dados .col {
  width: 50%;
}

.matriculas-curso .turmas .turma-selected .dados .col h4,
.matriculas-curso .turmas .turma-selected h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin: 0px;
}

.matriculas-curso .turmas .turma-selected .dados .col p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma-selected .boxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  margin: 10px 0px 10px 0px;
}

.matriculas-curso .turmas .turma-selected .boxes .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background: #ffffff;
  border-radius: 4px;
  padding: 8px;
  width: auto;
  min-width: 125px;
}

.matriculas-curso .turmas .turma-selected .boxes .box h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #895cf2;
  margin-bottom: 0px;
  margin-right: 10px;
}

.matriculas-curso .turmas .turma-selected .boxes .box p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b89afd;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  width: 49%;
  min-height: 250px;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.matriculas-curso .turmas .turma .mais {
  position: absolute;
  right: 20px;
  top: 15px;
}

.matriculas-curso .turmas .turma h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 16px 0px 16px 16px !important;
}

.matriculas-curso .turmas .turma h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52, 68, 83, 0.5);
  margin: 0px 0px 10px 16px;
}

.matriculas-curso .turmas .turma .status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  margin: 0px 16px 8px 16px;
}

.matriculas-curso .turmas .turma .status .dot-green {
  width: 8px;
  height: 8px;
  background: #1ebe5e;
  border-radius: 4px;
  margin-right: 5px;
}

.matriculas-curso .turmas .turma .status p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1ebe5e;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma .dados {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin: 0px 16px 8px 16px;
  width: 100%;
}

.matriculas-curso .turmas .turma .dados .col {
  width: 50%;
}

.matriculas-curso .turmas .turma .dados .col h4,
.matriculas-curso .turmas .turma h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(52, 68, 83, 0.5);
  margin: 0px 16px 8px 16px;
}

.matriculas-curso .turmas .turma .dados .col h4 {
  margin: 0px !important;
}

.matriculas-curso .turmas .turma .dados .col p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma .boxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  margin: 10px 10px 10px 0px;
  padding: 0px 10px 0px 10px;
}

.matriculas-curso .turmas .turma .boxes::-webkit-scrollbar,
.matriculas-curso .turmas .turma-selected .boxes::-webkit-scrollbar {
  display: none;
}

.matriculas-curso .turmas .turma .boxes .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background: var(--blue-color-20);
  border-radius: 4px;
  padding: 8px;
  width: auto;
  min-width: 125px;
  margin: 0px 10px 0px 0px;
}

.matriculas-curso .turmas .turma .boxes .box h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--primary-color);
  margin-bottom: 0px;
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 0px;
}

.matriculas-curso .turmas .turma .boxes .box p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--blue-ligth);
  margin-bottom: 0px;
  margin-top: 0px;
}

.matriculas-curso .turmas .turma .detalhes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px;
  background: #ebeffc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

.matriculas-curso .turmas .turma .detalhes p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--primary-color);
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma .status .dot-red-turma {
  width: 8px;
  height: 8px;
  background: #ff4d4f;
  border-radius: 4px;
  margin-right: 5px;
}

.matriculas-curso .turmas .turma .status p#vermelho {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ff4d4f;
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma .status .dot-cinza-turma {
  width: 8px;
  height: 8px;
  background: rgba(52, 68, 83, 0.5);
  border-radius: 4px;
  margin-right: 5px;
}

.matriculas-curso .turmas .turma .status p#cinza {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 0px;
}

.matriculas-curso .turmas .turma-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 32%;
  min-height: 250px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px dashed var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.matriculas-curso .turmas .turma-line h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: var(--primary-color);
}

.editar-oferta {
  padding: 8px 15px;
  background-color: #EBEFFC;
  display: flex;
  margin-left: 8px;
  border-radius: 4px;
}

.editar-oferta h6 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
}
.editar-oferta p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  align-self: center;
  color: #1A4DA1;
  font-weight: 600;
  margin: 0px;
  /* cursor: pointer; */
}

.block-name-turma{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.block-name-turma h5{
  font-size: 1.2rem !important;
  flex:1;
  margin-bottom:0px !important;
}
.container-rev{
  width: 100%;
  height: 100%;
  background-color: red;
}
.card-form{
  position: relative;
}