.img-fondo {
	position: fixed;
	bottom: 0px;
	left: 0px;
	z-index: 1;
	min-width: 100% !important;
}

.menu-user {
	z-index: 2;
}

#row-aluno {
	z-index: 2 !important;
}

.matriculas {
	width: 100%;
}

.matriculas .turmas {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
}

.matriculas .turmas .turma-selected {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 16px;
	width: 98%;
	height: auto;
	background: var(--primary-color);
	border-radius: 4px;
	position: relative;
	cursor: pointer;
}

.matriculas .turmas .turma-selected .mais {
	position: absolute;
	right: 20px;
	top: 15px;
}

.matriculas .turmas .turma-selected h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.matriculas .turmas .turma-selected h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.5);
	margin-bottom: 10px;
}

.matriculas .turmas .turma-selected .status {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.matriculas .turmas .turma-selected .status .dot-green {
	width: 8px;
	height: 8px;
	background: #1EBE5E;
	border-radius: 4px;
	margin-right: 5px;
}


.matriculas .turmas .turma-selected .status p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1EBE5E;
	margin-bottom: 0px;
}

.matriculas .turmas .turma-selected .dados {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	width: 100%;
}

.matriculas .turmas .turma-selected .dados .col {
	width: 50%;
}

.matriculas .turmas .turma-selected .dados .col h4, .matriculas .turmas .turma-selected h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.5);
	margin: 0px;
}

.matriculas .turmas .turma-selected .dados .col p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.matriculas .turmas .turma-selected .boxes {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow: scroll;
	margin: 10px 0px 10px 0px;
}

.matriculas .turmas .turma-selected .boxes .box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 35px;
	background: #FFFFFF;
	border-radius: 4px;
	padding: 8px;
	width: auto;
	min-width: 125px;
}

.matriculas .turmas .turma-selected .boxes .box h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: var(--primary-color);
	margin-bottom: 0px;
	margin-right: 10px;
}

.matriculas .turmas .turma-selected .boxes .box p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: var(--blue-ligth);
	margin-bottom: 0px;
}

.matriculas .turmas .turma {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 16px;
	width: 345px;
	height: auto;
	background: #FFFFFF;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
}

.matriculas .turmas .turma .mais {
	position: absolute;
	right: 20px;
	top: 15px;
}

.matriculas .turmas .turma h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin-bottom: 0px;
}

.matriculas .turmas .turma h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: rgba(52, 68, 83, 0.5);
	margin-bottom: 10px;
}

.matriculas .turmas .turma .status {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.matriculas .turmas .turma .status .dot-green {
	width: 8px;
	height: 8px;
	background: #1EBE5E;
	border-radius: 4px;
	margin-right: 5px;
}

.matriculas .turmas .turma .status .dot-orange {
	width: 8px;
	height: 8px;
	background: #f59300;
	border-radius: 4px;
	margin-right: 5px;
}

.matriculas .turmas .turma .status p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1EBE5E;
	margin-bottom: 0px;
}

.matriculas .turmas .turma .dados {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	width: 100%;
}

.matriculas .turmas .turma .dados .col {
	width: 50%;
}

.matriculas .turmas .turma .dados .col h4, .matriculas .turmas .turma h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: rgba(52, 68, 83, 0.5);
	margin: 0px;
}

.matriculas .turmas .turma .dados .col p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #485D73;
	margin-bottom: 0px;
}

.matriculas .turmas .turma .boxes {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow: scroll;
	margin: 10px 0px 10px 0px;
}

.matriculas .turmas .turma .boxes::-webkit-scrollbar, .matriculas .turmas .turma-selected .boxes::-webkit-scrollbar {
	display: none;
}

.matriculas .turmas .turma .boxes .box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 35px;
	background: var(--blue-color-20);
	border-radius: 4px;
	padding: 8px;
	width: auto;
	min-width: 125px;
}

.matriculas .turmas .turma .boxes .box h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: var(--primary-color);
	margin-bottom: 0px;
	margin-right: 10px;
}

.matriculas .turmas .turma .boxes .box p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: var(--blue-ligth);
	margin-bottom: 0px;
}

.matriculas .box-responsavel-selected {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	min-width: 700px;
	width: 100%;
	height: auto;
	background: var(--primary-color);
	border-radius: 8px;
	margin-bottom: 15px;
}

.matriculas .box-responsavel-selected .dados {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 5px;
}


.matriculas .box-responsavel-selected .dados h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #FFFFFF;
	margin: 0px;
}

.matriculas .box-responsavel-selected .dados .funcao {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	width: auto;
	height: auto;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	margin-top: 12px;
	margin-bottom: 12px;
}

.matriculas .box-responsavel-selected .dados .funcao p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.matriculas .box-responsavel-selected .dados h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.matriculas .box-responsavel {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	min-width: 700px;
	width: 100%;
	height: auto;
	background: #FFFFFF;
	border: 1px solid #EBEFFC;
	box-sizing: border-box;
	border-radius: 8px;
}

.matriculas .box-responsavel .dados {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 5px;
}


.matriculas .box-responsavel .dados h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #1F2832;
	margin: 0px;
}

.matriculas .box-responsavel .dados .funcao {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	width: auto;
	height: auto;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	margin-top: 2px;
	margin-bottom: 2px;
}

.matriculas .box-responsavel .dados .funcao p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.matriculas .box-responsavel .dados h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: var(--primary-color);
	margin-bottom: 0px;
}

.matriculas .planos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	height: auto !important;
	margin-bottom: 20px;
}

.matriculas .planos .col-1 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 45%;
}

.matriculas .planos h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #6A7C96;
	margin-top: 5px;
	margin-bottom: 15px;
}


.matriculas .planos .col-2 {
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 17px 21px;
	height: auto;
	background: #FFFFFF;
	border-radius: 4px;
}

.matriculas .planos .col-2 h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
	margin-top: 0px;
}

.matriculas .planos .col-2 .line {
	border: 1px solid rgba(203, 212, 237, 0.2);
	width: 100%;
	margin-bottom: 15px;
}

.matriculas .planos .col-2 .registro, .matriculas .planos .col-2 .registro-final {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
}

.matriculas .planos .col-2 .registro h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6A7C96;
	display: flex;
	align-items: center;
}

.matriculas .planos .col-2 .registro h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #344453;
}

.matriculas .planos .col-2 .registro h5#red {
	color: #FF0000;
}

.matriculas .planos .col-2 .registro-final h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #344453;
}

.matriculas .planos .col-2 .registro-final h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
}

.matriculas .pagamentos {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 20px;
	width: 800px;
	margin-top: 25px;
}

.matriculas .box-contrato {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 135px;
	background: #FFFFFF;
	border: 1px solid #EBEFFC;
	box-sizing: border-box;
	border-radius: 8px;
	margin-bottom: 25px;
	cursor: pointer;
}

.matriculas .box-contrato h4 {
	margin-top: 10px;
	margin-bottom: 0px;
}

.matriculas .box-contrato-selected {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 135px;
	background: #895CF2;
	border-radius: 8px;
	margin-bottom: 25px;
	cursor: pointer;
}

.matriculas .box-contrato-selected h4 {
	margin-top: 10px;
	margin-bottom: 0px;
	color: #FFFFFF;
}

#row-aluno h3 {
	margin-bottom: 8px;
}

#row-aluno h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485D73;
}

/* Cursos Matricula */

.matriculas-curso {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.pagamento-credito {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 230px;
	background: #fff;
	height: 110px;
	border-radius: 8px;
	padding: 16px;
	margin-top: 10px;
	
}

.pagamento-credito .cartao-texto {
	position: static;
	width: 192px;
	height: 24px;
	left: 16px;
	top: 58px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #344453;
}