.container-header{
  display: flex;
  width: 85%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}
.container-header :nth-child(1){
  width: 100%;
}
.render-modal{
 display: flex;
 flex-direction: column;
 align-items: center;
 text-align: center;
 /* max-height: 545px; */
 /* overflow: auto; */
}
.render-modal figure, video{
  width: 100%;
  height: 30%;
  border-radius: 5px;
  margin: 10px 0px;
}
.image img{
  width: 80%;
}
.input-box-date{
  align-self: flex-end;
  width: 40%;
  text-align: center;
  background-color: rgb(250, 250, 250);
  padding: 5px 8px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.modal-title {
  color: rgba(255, 0, 0, 0.800);
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}
.modal-footer-button {
  border-radius: 5px;
}
.container-inputs{
  margin-top: 10px;
}
