.menu-box-revisao-float {
	left: 15%;
	top: 28%;
	position: absolute;
	max-width: 290px;
	min-width: 280px;
}

.menu-box-revisao-float .bloco-1 {
	width: 100%;
	background-color: #ffffff;
	padding: 16px;
	border-radius: 4px;
	margin-bottom: 20px;
	margin-top: 30px;
}

.menu-box-revisao-float .bloco-1 h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #6a7c96;
	margin-bottom: 0px;
}

.menu-box-revisao-float .bloco-1 h1 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
}

.menu-box-revisao-float .bloco-1 h1#title {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	color: #344453;
	max-width: 100%;
}

.menu-box-revisao-float .bloco-1 button.adicionar,
.menu-box-revisao-float .bloco-2 .documentos button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 100%;
	height: 40px;
	background: #895cf2;
	border-radius: 4px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
}

.menu-box-revisao-float .bloco-2 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px;
	width: 100%;
	height: 155px;
	background: #ffffff;
	box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
	border-radius: 8px;
}

.menu-box-revisao-float .bloco-2 .franja {
	width: 9px;
	height: 100%;
	background: #e8d31a;
	border-radius: 8px 0px 0px 8px;
}

.menu-box-revisao-float .bloco-2 .documentos {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
	height: 100%;
}

.menu-box-revisao-float .bloco-2 .documentos .descricao {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px;
}

.menu-box-revisao-float .bloco-2 .documentos .descricao h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.menu-box-revisao-float .bloco-2 .documentos .descricao span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(52, 68, 83, 0.5);
	margin-bottom: 7px;
}

/*DEtalhes */

.menu-box-revisao-float .bloco-1 .mas {
	position: absolute;
	right: 15px;
	top: 15px;
}

.menu-box-revisao-float .bloco-1 .ativo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.menu-box-revisao-float .bloco-1 .ativo .dot-verde {
	height: 5px;
	width: 5px;
	border-radius: 2px;
	background-color: #1ebe5e;
	margin-right: 8px;
}

.menu-box-revisao-float .bloco-1 .ativo span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
}

#boxes-tags-detalhe {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
	width: 100%;
	margin-top: 15px;
}

.menu-box-revisao-float .bloco-1 .button-primary span {
	color: #ffffff !important;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}

.tag-gestor#tag {
	width: 110px;
	margin-top: 15px;
}

.tag-gestor#tag span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #895cf2;
}

#standy-campanha {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#dot-orange-campanhas {
	width: 8px;
	height: 8px;
	background-color: #f59300;
	border-radius: 8px;
	margin-right: 7px;
}

#standy-campanha span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #f59300;
}

#tag-colaborador-menu {
	margin-top: 15px;
	margin-bottom: 15px;
}

#menu-colaborador {
	max-width: 250px !important;
	min-width: 235px !important;
	width: 240px !important;
}

.avatar-menu-detalhe {
	max-width: 120px;
	width: 115px;
	height: 115px;
	border-radius: 60px;
	background-color: tomato;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 15px;
}

#dot-green-aluno {
	width: 8px;
	height: 8px;
	background-color: #1ebe5e;
	border-radius: 8px;
	margin-right: 7px;
}

#standy-campanha span#green {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
}
