@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary-blue-base: #1a4da1;
  --secondary-dark-blue: #92a9cb;
  --primary-gray-base: #344453;
  --primary-gray-base-50: #e0e0e0;
  --primary-gray-base-80: #9a9a9a;
  --primary-silver-dark: #cbd4ed;
  --azul-happy: #0c306c;
  --azul-secundario: #ebeffc;
  --cinza-azulado: #90a4ae;
  --cinza-azulado-50: #eceff1;
  --cinza-azulado-100: #cfd8dc;
  --cinza-azulado-200: #b0bec5;
  --cinza-azulado-300: #90a4ae;
  --cinza-azulado-400: #78909c;
  --cinza-azulado-500: #607d8b;
  --cinza-azulado-600: #546e7a;
  --cinza-azulado-700: #455a64;
  --cinza-azulado-800: #37474f;
  --cinza-azulado-900: #263238;
  --vermelho-atrasado: #f44336;
  --vermelho-50: #ffebee;
  --amarelo-hoje: #ffa000;
  --amarelo-50: #fff8e1;
  --verde-futuro: #4caf50;
  --verde-whatsapp: #8bc34a;
  --verde-50: #dcedc8;
  --azul-ligacoes-50: #e1f5fe;
  --azul-ligacoes-500: #03a9f4;
  --roxo-presencial-50: #ede7f6;
  --roxo-presencial-400: #7e57c2;
  --laranja-email: #ff5722;
  --white: #ffffff;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.container-happy {
  background-color: var(--cinza-azulado-50);
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100vh;
  max-width: 100vw;
}

/*Flex*/
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

/*width*/
.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100% !important;
}

/*height*/
.h-5 {
  height: 5%;
}

.h-10 {
  height: 10%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-40 {
  height: 40%;
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100% !important;
}

/* Margin */

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

/*Background */

.b-blue {
  background-color: var(--azul-secundario) !important;
}

.b-gray {
  background-color: var(--primary-gray-base-50) !important;
}

.b-red {
  background-color: var(--vermelho-50) !important;
}

.b-amarelo {
  background-color: var(--amarelo-50) !important;
}

.b-verde {
  background-color: var(--verde-50) !important;
}

/*Elements*/

.input-happy {
  border: 1px solid var(--cinza-azulado-900);
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
  min-width: 100%;
  height: 50px;
  max-height: 55px;
}

.input-happy-limitado {
  max-width: 60% !important;
  min-width: 60% !important;
}

.input-modal {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  max-width: 100%;
  min-width: 100%;
  height: 40px;
  max-height: 45px;
}

.input-modal2 {
  border: 1px solid rgba(0, 0, 0, 1);
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  height: 30px;
  max-height: 45px;
}

.input-textarea {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  max-width: 100%;
  min-width: 100%;
  height: auto;
  max-height: 300px;
}

.button-primary {
  background-color: var(--primary-color) !important;

  color: var(--white-color) !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;

  height: 40px !important;
  /*width: auto;*/
  /*max-width: 100% !important;*/

  border-radius: 8px !important;
  border: 0px !important;

  display: flex !important;
  justify-content: center;
  align-items: center;

  padding: 0 16px !important;
}

.button-primary:hover {
  background-color: var(--blue-dark) !important;
}

.button-blue {
  background: var(--azul-secundario) !important;
  border-radius: 4px !important;
  color: var(--azul-happy) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 50px !important;
  max-width: 100% !important;
  min-width: 150px !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

.button-duplicate {
  background: var(--cinza-azulado-50) !important;
  border-radius: 4px !important;
  color: var(--cinza-azulado-400) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 45px !important;
  max-width: 100% !important;
  min-width: 150px !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  margin-left: 10px !important;
  cursor: pointer;
}

.button-descartar {
  background: var(--vermelho-50) !important;
  border-radius: 4px !important;
  color: var(--vermelho-atrasado) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 45px !important;
  max-width: 100% !important;
  min-width: 150px !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  margin-left: 10px !important;
  cursor: pointer;
}

.button-venda {
  background: var(--verde-50) !important;
  border-radius: 4px !important;
  color: var(--verde-futuro) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 45px !important;
  max-width: 100% !important;
  min-width: 150px !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  margin-left: 10px !important;
  cursor: pointer;
}

.button-red {
  background: var(--vermelho-atrasado) !important;
  border-radius: 4px !important;
  color: var(--white) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 45px !important;
  max-width: 100% !important;
  min-width: 150px !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

.button-red:hover {
  background: var(--azul-happy) !important;
}

/* Antd */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #344453 !important;
}

input:focus {
  outline-offset: 0px !important;
  outline: none !important;
}

::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
}

.ant-select-selection-item {
  color: rgba(52, 68, 83, 0.5) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item {
  border: 0px solid var(--azul-happy) !important;
  border-radius: 16px !important;
  background-color: transparent !important;

  a {
    padding: 0px !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: var(--azul-happy) !important;
  }
}

.ant-pagination-item-active {
  border: 1px solid var(--azul-happy) !important;
  border-radius: 16px !important;

  a {
    color: var(--azul-happy) !important;
  }
}

.ant-pagination-prev {
  .ant-pagination-item-link {
    border: 1px solid var(--azul-happy) !important;
    border-radius: 16px !important;
    background-color: var(--azul-happy) !important;
    color: var(--white);
  }
}

.ant-pagination-disabled {
  .ant-pagination-item-link {
    color: var(--white) !important;
  }
}

.ant-pagination-next {
  .ant-pagination-item-link {
    border: 1px solid var(--azul-happy) !important;
    border-radius: 16px !important;
    background-color: var(--azul-happy) !important;
    color: var(--white);
  }
}

.ant-input:focus,
.input-modal:focus,
.input-textarea:focus {
  border-right-width: 0px !important;
  outline: none !important;
  outline-offset: 0px !important;
  border-color: #6200ee !important;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-input:hover,
.input-modal:hover,
.input-textarea:hover {
  border-color: #6200ee !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--azul-secundario) !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--azul-happy);
  }
}

.modal-leads-atrasado .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--vermelho-atrasado) !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
  }
}

.modal-leads-hoje .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--amarelo-hoje) !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
  }
}

.modal-leads-descartado .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--primary-gray-base-50) !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-gray-base-80);
  }
}

.modal-leads-futuro .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: var(--verde-futuro) !important;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
  }
}

#text-note {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--cinza-azulado-400);
}

.text-cinza {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--cinza-azulado-300);
}

.text-blue {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--azul-happy);
}


.ant-radio-checked .ant-radio-inner {
  border-color: var(--azul-happy) !important;
}