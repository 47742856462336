.dados__title-upgrade-transferir-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-bottom: 24px;

  h4 {
    margin-bottom: 0 !important;
  }
}

.title-upgrade-transferir-button__buttons {
  display: flex;
  gap: 16px;
}

.box-revisao .dados button {
  position: relative !important;
}
