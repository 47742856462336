.user-row-rev {
	background-color: white;
	padding: 10px;
	margin-top: 7%;
	min-width: 610px;
    p{
        font-size: 0.9rem;
    }
}
.header {
	display: flex;
	flex-direction: row;
	padding: 5px 0px;
	h3 {
		font-weight: bold;
		font-size: 1.1em;
	}
}
.botoes {
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

#voltar {
	font-size: 0.2em;
	width: 70px;
	border: 1.5px solid #ebeffc;
	border-radius: 4px;
	padding: 2px 16px;  
}
h5{
	font-size: 16px;
}