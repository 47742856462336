.container-happy {
  background-color: var(--cinza-azulado-50);
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100vh;
  max-width: 100vw;
}

.dashboard-crm {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0px 0px 0px 0px;

  .area {
    position: relative;
    margin: 30px 45px 30px 45px;
    height: auto;
    border-radius: 8px;
    background-color: var(--white);
    overflow-x: hidden;

    .header-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 70px;
      height: auto;
      background: var(--azul-happy);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px 8px 0px 0px;
      padding: 20px 24px;

      h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--white);
      }
    }

    .body-area {
      padding: 24px;

      .grafic-line {
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        padding: 30px 20px;
      }

      .select-box {
        position: relative;
        margin-top: -20px;

        .text-over-input {
          position: relative;
          top: 10px;
          left: 20px;
          background-color: var(--white);
          width: fit-content;
          padding: 0x 10px;
          text-align: center;

          small {
            color: var(--cinza-azulado-900);
          }
          small:hover {
            color: #6200ee;
          }
        }
      }

      .table {
        border: 1px solid var(--cinza-azulado-100);
        border-radius: 2px;
        overflow-x: scroll;
        max-width: calc(100vw - 238px);
        flex-wrap: wrap;

        .table-header {
          min-height: 60px;
          height: 60px;
          border-bottom: 2px solid #d9d5ec;

          .table-item {
            height: 100%;

            span {
              font-family: Open Sans;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 24px;
              color: var(--cinza-azulado-500);
            }
          }
        }

        .table-footer {
          padding: 15px 10px;
          justify-content: flex-end;
          width: calc(100vw - 238px);
        }
      }

      h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: var(--cinza-azulado-500);
      }

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--cinza-azulado-500);
      }
    }
  }

  .area-tasks {
    position: relative;
    margin: 10px 25px 10px 25px;
    height: auto;
    border-radius: 8px;
    background-color: transparent;
    overflow-x: hidden;

    .area-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 100vh;
      height: auto;
      max-height: 100vh;
      background: var(--white);
      border-radius: 8px 8px 8px 8px;
      padding: 0px;

      .header {
        background: var(--azul-happy);
        padding: 24px 14px;
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        height: unset !important;
        
        h3 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: var(--white);
        }
      }

      .body {
        padding: 14px 14px 0px 14px;
        overflow-y: scroll;

        .title-default {
          margin-bottom: 20px;
          h4 {
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: var(--cinza-azulado-500);
          }

          p {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--cinza-azulado-500);
          }
        }
      }
    }
  }
}
