#novo-lead-step-1 {
  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
  }
  h3 {
    color: #6a7c96 !important;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
  .container-titulo {
    margin-bottom: 20px;
  }

  .inputNumberIntraExtra {
    width: 100% !important;
  }
}
