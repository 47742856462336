.container-coleta-informacao-login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100vh;
    padding: 20px;
    background-image: linear-gradient(#1C6491, #021E4C);
}

.container-coleta-informacao-login img {
    position: absolute;
}

.box-coleta-informacao-login {
    position: relative;
    max-width: 400px;
    padding: 20px;
}

.box-coleta-informacao-login p {
    color: #fff;
    font-size: 0.9em;
    margin-top: 10px;
}




.container-coleta-informacao {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-image: linear-gradient(#1C6491, #021E4C);
}

.box-form-coleta-informacao {
    max-width: 800px;
    margin: auto;
}

.box-img-header {
    position: relative;
    margin: auto;
}

.box-img-header h2 {
    position: absolute;
    left: 5%;
    bottom: 10%;
    font-size: 2em;
    font-weight: 900;
    color: #fff;
}

.title-coleta-informacao {
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    color: #fff;
    margin: 20px 0 20px 0 !important;
}

.box-coleta-informacao {
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
}

.input-coleta-informacao {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid #ebeffc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input-alin-info {
    padding-left: 11px !important;
    padding-right: 11px !important;
}

.box-button-coleta-informacao {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

@media (max-width: 800px) {
    .container-coleta-informacao {
        height: 100vh;
        overflow-y: scroll;
    }

    .box-modal-finalizacao {
        padding: 20px;
        background: url("../../assets//img//coleta-informacao-lead/agradecimento-celular.png") no-repeat center !important;

    }

    .title-coleta-informacao {
        font-size: 1.8em;
    }

    .box-coleta-informacao-login p {
        font-size: 1.8em;
    }
}

@media (max-width: 400px) {
    .container-coleta-informacao {
        height: 100vh;
        overflow-y: scroll;
    }


}

.container-modal-finalizacao {
    width: 100%;
    height: 100vh;
    padding: 20px;
    background-image: linear-gradient(#1C6491, #021E4C);
}

.box-modal-finalizacao {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: url("../../assets//img//coleta-informacao-lead/agradecimento.png") no-repeat center;

}