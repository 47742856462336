.modalContainer{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBox h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
}

.modalBox h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485D73;
  margin-bottom: 24px;
}

.registros-arquivos{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #EBEFFC;
  padding-bottom: 20px;
  padding-top: 20px;
}

.registros-arquivos .data{
  width: 15%;
}

.registros-arquivos .data span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
}

.registros-arquivos .arquivo{
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.registros-arquivos .arquivo p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-left: 10px;
}

.registros-arquivos .botao{
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registros-arquivos .botao button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 145px;
  height: 35px;
  background: #895CF2;
  border-radius: 4px;
  color:#FFFFFf;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.icon-alt{
  margin-right: 5px;
}

.carregar{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carregar button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  background: #EBEFFC;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #895CF2;
}