.lead-sem{
  position: relative;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  cursor:pointer;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 20px !important;
  }

  .status{
    margin-left: 10px !important;
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--azul-happy);
    color:var(--white);
    font-size: 0.9rem;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-left: 10px !important;
  }

  .line-lead{
    border: 2px solid var(--azul-happy);
    width: 100%;
    margin: 10px 0px;
  }

  .icons{
    margin-bottom: 14px !important;
    padding-right: 14px;

    .starts{
      border-right: 2px solid var(--cinza-azulado-200);
      padding: 5px 15px 0px 15px;
      margin-right: 10px;

      input[type=checkbox] {
        display: none;

      }

      label{
        svg{
          margin-left: 5px;
        }
      }
    }
  }
}

.lead-atrasado{
  position: relative;
  background-color: var(--vermelho-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  cursor:pointer;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 20px !important;
  }

  .status{
    margin-left: 10px !important;
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--vermelho-atrasado);
    color:var(--white);
    font-size: 0.9rem;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
    margin-left: 10px !important;
  }

  .line-lead{
    border: 2px solid var(--vermelho-atrasado);
    width: 100%;
    margin: 10px 0px;
  }

  .icons{
    margin-bottom: 14px !important;
    padding-right: 14px;

    .starts{
      border-right: 2px solid var(--cinza-azulado-200);
      padding: 5px 15px 0px 15px;
      margin-right: 10px;

      input[type=checkbox] {
        display: none;

      }

      label{
        svg{
          margin-left: 5px;
        }
      }
    }
  }
}

.lead-hoje{
  position: relative;
  background-color: var(--amarelo-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  cursor: pointer;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 20px !important;
  }

  .status{
    margin-left: 10px !important;
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--amarelo-hoje);
    color:var(--white);
    font-size: 0.9rem;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
    margin-left: 10px !important;
  }

  .line-lead{
    border: 2px solid var(--amarelo-hoje);
    width: 100%;
    margin: 10px 0px;
  }

  .icons{
    margin-bottom: 14px !important;
    padding-right: 14px;

    .starts{
      border-right: 2px solid var(--cinza-azulado-200);
      padding: 5px 15px 0px 15px;
      margin-right: 10px;

      input[type=checkbox] {
        display: none;

      }

      label{
        svg{
          margin-left: 5px;
        }
      }
    }
  }
}

.lead-futuro{
  position: relative;
  background-color: var(--verde-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  cursor: pointer;

  .dragg{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 20px !important;
  }

  .status{
    margin-left: 10px !important;
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--verde-futuro);
    color:var(--white);
    font-size: 0.9rem;
  }

  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }

  p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
    margin-left: 10px !important;
  }

  .line-lead{
    border: 2px solid var(--verde-futuro);
    width: 100%;
    margin: 10px 0px;
  }

  .icons{
    margin-bottom: 14px !important;
    padding-right: 14px;

    .starts{
      border-right: 2px solid var(--cinza-azulado-200);
      padding: 5px 15px 0px 15px;
      margin-right: 10px;

      input[type=checkbox] {
        display: none;

      }

      label{
        svg{
          margin-left: 5px;
        }
      }
    }
  }
}

.lead-descartado {
  position: relative;
  background-color: var(--primary-gray-base-50);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  height: auto;
  margin-bottom: 15px;
  cursor: pointer;

  .dragg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 20px !important;
  }

  .status {
    margin-left: 10px !important;
    margin-top: 3px !important;
    margin-bottom: 20px !important;
    padding: 3px 10px;
    background-color: var(--primary-gray-base);
    color: var(--white);
    font-size: 0.9rem;
  }

  h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-900);
    margin-left: 10px !important;
    margin-bottom: 5px !important;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--cinza-azulado-400);
    margin-left: 10px !important;
  }

  .line-lead {
    border: 2px solid var(--cinza-azulado-200);
    width: 100%;
    margin: 10px 0px;
  }

  .icons {
    margin-bottom: 14px !important;
    padding-right: 14px;

    .starts {
      border-right: 2px solid var(--cinza-azulado-200);
      padding: 5px 15px 0px 15px;
      margin-right: 10px;

      input[type=checkbox] {
        display: none;

      }

      label {
        svg {
          margin-left: 5px;
        }
      }
    }
  }
}