.container {
	/* height: 100vh; */
	position: absolute;
	width: 100vw;
	display: flex;
	/* flex-direction: row; */
	/* justify-content: space-between; */
	/* align-items: flex-end; */
}

.container-login {
	height: 100vh;
	/* position: absolute; */
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.logo-login {
	position: absolute;
	width: 200px;
	height: auto;
	left: 32px;
	top: 32px;
}

.img-layout {
	height: 80%;
	width: auto;
	bottom: 0px;
	left: 0px;
}

.formulario-box {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(/src/assets/img/login/tela\ login\ SPACE\ -\ background\ \(1\).jpg);
	background-position: left;
	background-repeat: no-repeat;
	background-size: cover;
}

.formulario {
	width: 490px;
	height: auto;
	.logo-principal-home{
		display: block;
		margin: 0 auto;
		max-width: 250px;
		margin-bottom: 5%;
	}
}

.formulario h3 {
	font-family:'Roboto-Regular' ;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 40px;
	color: var(--white-color);
	margin-bottom: 8px;

}

.formulario p {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--blue-transparent);
	margin-bottom: 8%;
}

.formulario .input {
	position: relative;
	width: 100%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 22px !important;
	margin-bottom: 24px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
}

.formulario .input-error {
	position: absolute;
	top: 56px;
	font-family: 'Open Sans';
	color: #e84118;
	font-style: normal;
	font-weight: 600;
}

.iconForm {
	margin-right: 8px;
}

.formulario .input input {
	width: 100%;
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #6a7c96;
}

.formulario .lembrete {
    display: flex;
    justify-content: flex-start; 
    width: 100%;
    margin-top: -20px; 
}

span#green-happy {
    color: #f1df43;
    text-decoration: none;
    font-size: 16px; 
}


.checkbox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.checkbox input {
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 2px;
	margin-right: 7px;
}

.checkbox span {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #4c5a68;
}

.lembrete span {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 40px;
	color: var(--white-color-50);
}

.formulario .button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;
	width: 488px;
	height: 56px;
	background: var(--primary-color);
	border-radius: 4px;
	margin-top: 24px;
	margin-bottom: 24px;
	cursor: pointer;
	opacity: 0.92;
	transition: 0.3s;
}

.formulario .button:hover {
	background-color: var(--primary-color) e8;
}

.button span {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}
.button-login {
    background-color: #f1df43 !important; 
    color: #ffffff ;           
    transition: 0.3s;
	height: 40px !important;
	width: 100%;
	margin: 20px 0 24px 0;
	span {
		font-family: 'Roboto-Regular';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #ffffff;
	}
}

.button-login:hover {
    background-color: #f1df43; 
}

.conta {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--white-color);
}

.cadastro {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: var(--green-color);
}

/* Escolher Unidade */

.boxes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.boxes .box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto 16px;
	width: 100%;
	height: 120px;
	background: #ffffff;
	border: 1px solid #cacddf;
	box-sizing: border-box;
	border-radius: 8px;
}

.boxes .box p {
	margin-top: 16px;
}

.boxes .box:hover {
	background: var(--primary-color);
	color: #ffffff;
}

.boxes-login {
	display: flex;
	flex-direction: row;
	position: relative;
}

.box-login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto 16px;
	width: 200px;
	height: 120px;
	background: #ffffff;
	border: 1px solid #cacddf;
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
	font-style: normal;
	font-weight: 600;
}

.box-login:hover {
	background: var(--primary-color) !important;
	color: white !important;
}

.box-login:hover svg {
	fill: white !important;
}

.logomarcas {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    width: 100%; 
    max-width: 600px;  
    box-sizing: border-box;
	img {
		max-width: 150px;
		height: auto; 
	}
}