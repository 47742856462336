.modalContainer{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-user{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  position: relative;
  width: 776px;
  height: auto;
  background: #FFFFFF;  
  box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
  border-radius: 8px;
}

.modal-user h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
}

.modal-user h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485D73;
  margin-bottom: 24px;
}

.modal-user .boxes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-user .boxes .box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 48%;
  margin: auto;
  height: 140px;
  background: #FFFFFF;
  border: 1px solid #EBEFFC !important;
  box-sizing: border-box;
  border-radius: 8px;
}

.modal-user .boxes .box:hover{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin: auto;
  width: 48%;
  height: 140px;
  background: var(--blue-color-50);
  box-sizing: border-box;
  border-radius: 8px;
}

.modal-user .boxes .box p,.modal-user .boxes .box-select p{
  margin-top: 16px;
}

.modal-user .boxes .box-select{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin: auto;
  width: 48%;
  height: 140px;
  background: var(--primary-color);
  border-radius: 8px;
}

.modal-user .boxes .box-select p{
  color: #FFFFFF;
}

.modal-user .line{
  border: 1px solid #EBEFFC;
  width: 100%;
  margin-top:20px;
  margin-bottom: 20px;
}

.modal-user .botoes{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.modal-user .botoes .btn-cancel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 146px;
  height: 40px;
  background-color: transparent;
  border: 1.5px solid #EBEFFC;
  box-sizing: border-box;
  border-radius: 4px;
  color: #895CF2;
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.modal-user .botoes .btn-make{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 146px;
  height: 40px;
  background: #895CF2;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.modal-user .fechar{
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px; 
  width: 40px;
  height: 40px;
  background: #EBEFFC;
  border-radius: 8px;
}
