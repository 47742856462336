.input-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
    width: 100%;
    height: auto;
    background: white;
    border: 1px solid rgba(52, 68, 83, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 15px;
    width: 100%;
}

.input-box p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    margin: 0px;
    margin-bottom: 2px;
}

.input-box.disabled, .input-box.disabled input {
    background-color: #f5f5f6!important;
}

.form-modal-oferta {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
}

.modalContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    /* z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000; */
}

.modal-user-franquia {
    display: flex;
    flex-direction: column;
    justify-content: flex-start!important;
    align-items: flex-start!important;
    padding: 0px;
    position: relative;
    width: 540px;
    height: auto;
    background: #ffffff;
    box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
    border-radius: 8px;
}

.modal-user-franquia h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 22px;
    color: #344453;
    margin: 16px!important;
    text-align: left!important;
}

.modal-user-franquia h6 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(52, 68, 83, 0.5);
    margin: 0px 30px 12px 30px;
}

.modal-user-franquia .line {
    border: 1px solid #ebeffc;
    width: 100%;
    margin-top: 0px!important;
    margin-bottom: 20px;
}

.modal-user-franquia .botoes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px 0px 16px;
}
