p.faltoso {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 9px !important;
}

.presenca-item {
    display: flex;
    padding: 6px;
    cursor: not-allowed;
}

.presenca-item:hover {
    background-color: #f1f1f1;
    padding: 6px;
    border-radius: 8px;
}

.presenca-item span {
    align-self: center;
}
