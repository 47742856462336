.container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    div{
        text-align: center;
        min-width: 250px;
    }

    div button{
        margin: auto;
    }
}
.btn_disabled{
    cursor: not-allowed;
    opacity: 0.8;
}

.text{
    text-align: center;
    font-weight: bold;
    margin-top: 0.8rem;
}