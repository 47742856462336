.box-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
}

h3.title {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 48px;
	color: #353f49;
}

.adicionar {
	width: 192px;
	height: 42px;
	background: #895cf2;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0.92;
	transition: 0.3s;
}

.adicionar:hover {
	background-color: #895cf2e8;
}

.adicionar .circle {
	background-color: #b89afd80;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26px;
	height: 26px;
	border-radius: 26px;
	margin-right: 8px;
}

.adicionar span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}

.input-text {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	width: 548px;
	height: 40px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
}

.input-text input {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	margin-left: 5px;
	width: 100%;
	color: #6a7c96;
	background-color: #ffffff;
}

.filtro-estado select,
.filtro-cidade select {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #485d73;
	margin-right: 10px;
}

.filtro-cidade {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	width: 170px;
	height: 40px;
	background: rgba(106, 124, 150, 0.05);
	border-radius: 4px;
	margin-left: 10px;
}

.tabela {
	width: 100%;
	padding-bottom: 10px;
}

.titles {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #cbd4ed;
	padding: 0px 0px 10px 20px;
	margin-bottom: 16px;
}

.titles .item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	height: auto;
	border-radius: 0px;
	padding: 0px;
	background-color: transparent !important;
}

.titles .item span {
	color: #6a7c96;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	margin-right: 5px;
}

.arrows {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.registros {
	display: flex;
	flex-direction: column;
}

.registros-body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 82px;
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(152, 152, 152, 0.1);
	border-radius: 8px;
	padding-left: 20px;
	margin-bottom: 8px;
}

.registros-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.registro .gestor {
	color: #895cf2;
}

.estado {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 8px;
}

.estado .dot {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: #1ebe5e;
	margin-right: 5px;
}

.estado span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
}

.registro-opcao {
	width: 64px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
}

.registro-opcao .circle {
	width: 32px;
	height: 32px;
	border-radius: 32px;
	background-color: rgba(203, 212, 237, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.estado-amarelo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 8px;
}

.estado-amarelo .dot {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: #f59300;
	margin-right: 5px;
}

.estado-amarelo span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #f59300;
}

.estado-vermelho {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 8px;
}

.estado-vermelho .dot {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: #ff4d4f;
	margin-right: 5px;
}

.estado-vermelho span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #ff4d4f;
}

.estado-cinza {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 8px;
}

.estado-cinza .dot {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.5);
	margin-right: 5px;
}

.estado-cinza span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.5);
}

.paginacao {
	display: flex;
	justify-content: center;
	margin: 20px 0px;
}

/* Detalhes */

.registros-body-detalhes .row-1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.registros-body-detalhes .row-1 .registro-opcao {
	cursor: pointer;
}

.row-2 .dados {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.row-2 .dados h6,
.row-2 .modelos h6,
.row-2 .negocios h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #6a7c96;
}

.row-2 .dados span,
.row-2 .modelos span,
.row-2 .negocios span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.row-2 .line {
	border: 1px solid #cbd4ed;
	height: 100%;
	margin-left: 25px;
	margin-right: 25px;
}

.row-2 .modelos,
.row-2 .negocios {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
}

.registros-body-detalhes button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 100%;
	height: 40px;
	background: #895cf2;
	border-radius: 4px;
	color: #ffffff;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}

#registro-icones {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
