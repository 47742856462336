.label-and-input-number {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(58, 58, 58, 0.6);
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 8px;

    width: 100%;
    height: 40px;

    background: #ffffff;

    border: 1px solid rgba(52, 68, 83, 0.05);

    box-sizing: border-box;

    border-radius: 4px;
  }
}