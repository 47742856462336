
.graph-stemplay{
    width: 700px;
    height: 300px;
}

@media (max-width: 830px) {
    .graph-stemplay {
        width: 580px;
    }
}

@media (max-width: 768px) {
    .graph-stemplay {
        width: 550px;
    }
}

@media (max-width: 660px) {
    .graph-stemplay {
        width: 450px;
    }
}

@media (max-width: 430px) {
    .graph-stemplay {
        width: 250px;
    }
}

@media (max-width: 380px) {
    .graph-stemplay {
        width: 230px;
    }
}

@media (max-width: 320px) {
    .graph-stemplay {
        width: 200px;
    }
}