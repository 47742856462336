#colaboradores {
  width: 20%;
}

.colaborador {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.colaborador .avatar {
  max-width: 120px;
  width: 115px;
  height: 115px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar {
  max-width: 120px;
  width: 115px;
  height: 115px;
  border-radius: 60px;
  background-color: #cbd4ed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar .camera {
  background-color: var(--primary-color);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.colaborador .avatar .camera {
  background-color: #895cf2;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

#row-colaborador {
  padding-top: 150px !important;
}

.box-colaborador {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.box-colaborador .avatar {
  margin-right: 30px;
}

.email-message{
  font-size: 0.7rem !important;
  color: #FF0000 !important;
}