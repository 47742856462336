.student-basic-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: var(--white-color);

  width: 100%;

  padding: 24px;

  border-radius: 12px;
}

.student-basic-info-wrapper__title-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #344453;
  }
}

.title-buttons__buttons-container {
  display: flex;
  align-items: center;
  gap: 16px;
   .button-matricular{
    color:#0c306c ;
    background-color: #b6c0d3;
    min-width: 150px; 
    height: 50px; 
    font-size: 16px;
    border: 1.5px solid #ebeffc;
    box-sizing: border-box ;
    border-radius: 8px ;
  }
}
.title-buttons__buttons-container  .button-matricular:hover {
  background-color: #f1df43 !important;
  color: #000;
}

.student-basic-info-wrapper__courses-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
