.container-offer-page {
  margin-top: 90px;
  padding: 0 30px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  min-height: 100vh;
  height: auto;
}

.container-offer-page .aluno-box {
  width: 25%;
  background-color: white;
  padding: 20px;
  border-radius: 0px;
  .button-primary{
    height: 50px !important;
  }
}

.container-offer-page .aluno-box .dados {
  margin-bottom: 20px;
  width: 100%;
}

.container-offer-page .aluno-box .dados h4,
.container-offer-page .aluno-box .oferta h4,
.container-offer-page .aluno-box .resultado h4 {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: #1f2832;
}

.container-offer-page .aluno-box .dados p {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  color: #1f2832;
}

.container-offer-page .aluno-box .dados p span {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: #1f2832;
}

.container-offer-page .aluno-box .dados .responsavel-box {
  background-color: #ebeffc;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
}

.container-offer-page .aluno-box .dados .responsavel-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  color: #1a4da1;
}

.container-offer-page .aluno-box .oferta {
  width: 100%;
  margin-bottom: 30px;
}

.container-offer-page .aluno-box .oferta .boxes {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;

  margin-top: 20px;
}

.container-offer-page .aluno-box .resultado {
  width: 100%;
}

.container-offer-page .aluno-box .resultado .box-resultado {
  margin: 20px 0px;
}

.container-offer-page .aluno-box .resultado .box-resultado h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: #1f2832;
}

.container-offer-page .aluno-box .resultado .box-resultado p {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  color: #1f2832;
}

.container-offer-page .box-calendar {
  flex: 1;
}

.container-offer-page .box-calendar .filtros {
  width: 100%;
  margin: 10px 0px !important;
  padding: 20px 0px;
}

.container-offer-page .box-calendar .filtros .title .number {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #cbd4ed80;
  font-weight: bold;
  color: #1a4da1;
  margin-right: 10px;
}

.container-offer-page .box-calendar .filtros .title h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  color: #344453;
}

.container-offer-page .box-calendar .filtros .filters p {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #485d73;
}

.container-offer-page .box-calendar .filtros-box {
  border-radius: 8px;
  background-color: #ebeffc;
  width: 100%;
  padding: 20px;
  height: auto;
  margin-bottom: 20px;
}

.container-offer-page .box-calendar .calendario {
  width: 100%;
}

.container-offer-page .box-calendar .calendario .title {
  flex: 1;
}

.container-offer-page .box-calendar .calendario .filters {
  width: auto;
}

.container-offer-page .box-calendar .calendario .header-calendario {
  background-color: #cbd4ed;
  border-radius: 8px 8px 0px 0px;
  height: 60px;
  padding: 10px 20px;
}

.container-offer-page .box-calendar .calendario .header-calendario h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #1a4da1;
}

.container-offer-page .box-calendar .calendario .body-calendario {
  padding: 10px;
}
