.contrato-step-revisao__progress {
	height: 4px;
	width: 100%;

	position: fixed;

	top: 88px;
	left: 0px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	z-index: 3;
}

.contrato-step-revisao-wrapper {
	display: flex;
	gap: 32px;

	height: auto;

	padding: 120px 188px;
}

.wrapper-column__menu-revisao-wrapper button {
	width: fit-content;
	height: 40px !important;
}

.wrapper-column__menu-revisao-wrapper,
.wrapper-column__table-revisao-wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.wrapper-column__menu-revisao-wrapper {
	width: 30%;
}

.wrapper-column__table-revisao-wrapper {
	width: 70%;
}

.table-revisao-wrapper__top-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top_content__titulo {
	font-weight: 600;
	font-size: 20px;
	line-height: 27px;
	color: #344453;
}

.top-content__close-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 8px;

	width: 40px;
	height: 40px;

	background: #ebeffc;

	border-radius: 8px;
}

.table-revisao-wrapper__revisao-dados {
	display: flex;
	flex-direction: column;
	gap: 32px;

	background-color: var(--white-color);

	padding: 16px;

	border-radius: 8px;
}

.revisao-dados__revisao-dados-container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.revisao-dados-container__titulo-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.titulo-button__titulo {
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
}

.revisao-dados-container__infos-contrato {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 16px;
}

.infos-contrato__contrato-label-text {
	display: flex;
	flex-direction: column;
}

.table-label__label,
.contrato-label-text__label {
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #6a7c96;
	text-transform: uppercase;
}

.contrato-label-text__text {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
}

.revisao-dados-container__line-divisor {
	border: none;
	height: 1px;
	background-color: #ebeffc;
}

.revisao-dados-container__subtitle {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.revisao-dados-container__table-label {
	display: flex;
	flex-direction: column;
}

.table-label__table-wrapper {
	border: 0;

	tr,
	td {
		border: 0;
	}
}

.table-wrapper__header-row {
	background-color: #dedede;
	font-weight: 700;
}

.revisao-datos__anexo-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	background: #fff;

	border: 1px solid #ebeffc;

	box-sizing: border-box;

	border-radius: 8px;

	width: 100%;

	.box-documentos {
		width: 100%;
	}

	.documentos {
		display: flex;
		flex-direction: column;
		gap: 12px;

		padding: 16px;

		height: 100%;
		width: 100%;

		.descricao {
			h6 {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				color: #1f2832;
			}

			span {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				color: rgba(52, 68, 83, 0.5);
			}
		}

		.button-upload {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			padding: 16px;

			width: 100%;

			box-sizing: border-box;

			background-color: initial;

			color: var(--primary-color);
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}
	}
}
