.relatorios-box-wrapper, .cards-dash{
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 12px;
}

@media (min-width: 640px) {
	.relatorios-box-wrapper,.cards-dash {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media (min-width: 768px) {
	.relatorios-box-wrapper,.cards-dash {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (min-width: 1024px) {
	.relatorios-box-wrapper,.cards-dash {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media (min-width: 1280px) {
	.relatorios-box-wrapper,.cards-dash {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

#colunas {
	border: 0.5px solid;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
	vertical-align: middle;
	min-height: 50px !important;
	font-size: 0.9rem;
	font-weight: 500;
}

#top {
	background-color: #dedede;
	font-weight: 700;
}

#tableConciliacao {
	margin-bottom: 20px;
}

.botoesConciliacao {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.botaoConciliacao {
	margin-right: 5px;
}

.saque {
	background: linear-gradient(#88d888, greenyellow);
}

.saqueValor {
	background: linear-gradient(#88d888, greenyellow);
	font-weight: 700;
}

.saquePago {
	font-weight: 700;
	background: linear-gradient(#88d888, greenyellow);
}

.scroll-tabelas {
	/* display: block;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
    min-height:300px; */
}

.rel-header {
	position: relative;
	padding-left: 56px;
	max-width: 70%;
	padding: 50px 20px 20px 56px;
}

.rel-header strong {
	font-weight: 600;
	margin-right: 5px;
}

  .cards-dash .box-relatorio {
	width: 100%;
	min-height: 150px;
	max-height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd; 
	border-radius: 8px;
	background-color: white;

	&:hover {
		border: 1px solid #c5cddc;
	}
  }