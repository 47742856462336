.menu-revisao-contrato {
	display: flex;
	flex-direction: column;
	gap: 16px;

	background-color: #fff;

	padding: 16px;

	border-radius: 8px;
}

.menu-revisao-wrapper__text-container {
	display: flex;
	flex-direction: column;
}

.text-container__label {
	font-size: 12px;
	line-height: 18px;
	text-transform: uppercase;
	color: #6a7c96;
}

.text-container__titulo {
	font-weight: 600;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	text-overflow: clip;
}

.text-container__titulo-secundario {
	font-weight: 400;
	line-height: 24px;
	color: #344453;
}
