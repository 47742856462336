.modal__pagarme .ant-modal-header {
    background-color: var(--pagar-me);
}

.modal__pagarme .ant-modal-title {
    font-weight: bold;
    color: #fff;
}

.modal__pagarme .ant-modal-footer {
    background-color: var(--pagar-me);
}

.modal__pagarme .ant-modal-footer button {
    background-color: #fff;
    color: var(--pagar-me);
}

.modal__pagarme .ant-modal-footer button:hover {
    background-color: var(--pagar-me);
    color: #fff;
    box-shadow: #fff 0px 0px 0px 2px;
}

.modal__pagarme .ant-modal-close-x {
    color: #fff;
}

.modal__pagarme .ant-modal-close-x:hover {
    color: #fff;
}