.arrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 28px;
  text-align: left;
  color: #ffffff;
  h3 {
    text-align: left;
    width: 100%;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
}

.arrow span {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: -15px;
}

.button-second-voltar{
  background-color: transparent !important;
  color: #ffffff !important;
  transition: 0.3s;
  width: '100%';
  height: 56px;
  margin-top: 10px;
  justify-self: left;
}

.esqueceu-senha{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

