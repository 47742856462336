.modalContainerFinanceiro{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-user-financeiro{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 700px;
  height: auto;
  max-height: 650px;
  background: #FFFFFF;  
  box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
  border-radius: 8px;
}

.modal-user-financeiro .body .button-fechar {
  right: 16px !important;
  top: 16px !important;
}

.modal-user-financeiro .body{
  padding: 16px 24px 16px 24px;
  width: 100%;
  overflow-y: scroll;
}

.modal-user-financeiro .body h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  margin-bottom: 0px;
}

.modal-user-financeiro .body h4{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6A7C96;
}

.modal-user-financeiro .body h5{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-top: 15px;
}

.modal-user-financeiro .body h6, .modal-user-financeiro .body .detalhes-aluno .data h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #344453;
}

.modal-user-financeiro .body .detalhes-aluno{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-user-financeiro .body .detalhes-aluno .data{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.modal-user-financeiro .body .detalhes-aluno .data h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #344453;
  margin-left: 10px;
}

.modal-user-financeiro .body .detalhes-aluno h6#link{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--primary-color);
  cursor: pointer;
}

.modal-user-financeiro .body .box-tabela{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width:100%;
  border: 1px solid #EBEFFC;
  box-sizing: border-box;
  border-radius: 8px;  
}

.modal-user-financeiro .body .box-tabela h5{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin: 0px;
}

.modal-user-financeiro .body .box-tabela .valor{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-user-financeiro .body .box-tabela .valor h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6A7C96;  
  margin-bottom: 0px;
}

.modal-user-financeiro .body .box-tabela .valor p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 0px;
}

.modal-user-financeiro .body .detalhes-curso{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.modal-user-financeiro .body .detalhes-curso .data{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.modal-user-financeiro .body .detalhes-curso .data img{
  margin-right: 10px;
}

.modal-user-financeiro .line{
  border: 1px solid #EBEFFC;
  width: 100%;
  margin-top:20px;
  margin-bottom: 20px;
}

.modal-user-financeiro .botoes{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding:0px 24px 0px 24px;
}

p#cor-red{
  color:var(--red-color);
}

p#cor-orange{
  color:var(--orange-color);
}

#valor-final{
  margin-bottom: 8px;
}

#registro-financeiro-selected-modal{
  background-color: var(--primary-color-20);
}

.ant-picker-dropdown  {
  z-index: 6000 !important;
}