h1.exc-title{
    font-size: 24px;
}

.row-blue{
    width: 100%;
    background-color: var(--blue-dark);
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0px;
}

.row-blue h2{
    font-family: Open Sans !important;
    color:white;
    font-weight: 800;
    font-size:22px;
    text-transform: uppercase;
}

.row-blue h6{
    font-family: Open Sans !important;
    color:white;
    font-weight: 700;
    font-size:12px;
    text-transform: uppercase;
}

.separator{
    height: 30px;
    width: 2px;
    background-color: white;
    margin: 0px 20px;
}

.box-geral{
    background-color: #6DB4F8;
    padding: 2px;
    text-align: center;
    width: 40%;
    border-radius: 2px;
}

.box-geral p{
    font-size: 10px;
}

.box-geral p span{
    font-weight: 700;
}

.box-metro{
    background-color: #FBAF1F;
    padding: 2px;
    text-align: center;
    flex:1;
    border-radius: 2px;
}

.box-metro p{
    font-size: 10px;
}

.box-metro p span{
    font-weight: 700;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
    box-shadow:none !important;
    transition:120ms !important;
}

.css-15v22id-MuiAccordionDetails-root, .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    padding: 0px !important;
}

.box-pilares{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
    min-height: 120px;
    /* padding: 20px; */
}

.box-pilares h6{
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
}

.box-pilares h2{
    font-weight: 800;
    font-size: 26px;
    color: #FFFFFF;
}

.pilar-blue{
    background-color: var(--blue-ligth);
}

.pilar-orange{
    background-color: #F57C00;
    width: 230px;
}

.pilar-yellow{
    background-color: #FBAF1F;
    width: 230px;
}

.pilar-red{
    background-color: #DC3735;
}

.pilar-green{
    background-color: #B0CB51;
    flex:1;
}

.minibox-pilares{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100px;
    min-height: 100px;
    padding: 20px;
    flex: 1;
}

.minibox-pilares h6{
    font-weight: 400;
    font-size: 10px;
    color: #444444;
    text-align: center;
}

.minibox-pilares h2{
    font-weight: 700;
    font-size: 16px;
    color: #444444;
}

.minibox-blue{
    background-color: rgba(31, 97, 204, 0.5);
}

.minibox-orange{
    background-color: rgba(255, 204, 128, 1);
}

.minibox-yellow{
    background-color: rgba(251, 227, 151, 1);
}

.minibox-red{
    background-color: rgba(250, 138, 139, 1);
}

.minibox-green{
    background-color: rgba(213, 238, 127, 1);
}

.title-accordion{
    font-weight: 700;
    color:var(--blue-dark);
    font-size: 14px;
    margin-top: 3px;
    margin-left: 3px;
}

.tabela-excelencia{
    width: 100%;
    padding-bottom: 10px;
}

.tabela-excelencia .titles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px;
    padding: 10px;
    margin-bottom: 0px;
    gap: 10px;
}

.tabela-excelencia .titles .item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 0px;
    padding: 0px;
    background-color: transparent !important;
    margin-bottom: 0px;
}

.tabela-excelencia .titles .item span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-right: 5px;
    color: var(--blue-dark);
}

.tabela-excelencia .titles .item span.title{
    font-weight: 700;
}

.border-title-excelencia{
    padding-bottom: 4px;
    border-bottom: solid 1px #cccccc;
}

.excelencia-mes{
    background-color: #EBEFFC;
    height: 40px;
    padding: 12px 16px;
}

.title-mes{
    font-weight: 700;
    color:var(--blue-dark);
    font-size: 12px;
}

.tabela-excelencia .body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px;
    padding: 10px;
    margin-bottom: 0px;
    gap: 10px;
}

.tabela-excelencia .body .item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: auto;
    border-radius: 0px;
    padding: 0px;
    background-color: transparent !important;
    margin-bottom: 0px;
}

.tabela-excelencia .body .item p{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #6A7C96;
}

.tabela-excelencia .body .item h5{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #344453;
}

.tag-excelencia{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 7px;
    border-radius: 15px;
    margin: 0px;
    gap: 5px;
    color: white;
}

.tag-excelencia img{
    width: 17px;
    height: auto;
}

.tag-excelencia h5{
    color: white !important;
    font-weight: 600 !important;
}

.tag-excelencia-matricula{
    background-color: var(--blue-ligth);
}

.tag-excelencia-alunos{
    background-color: #F57C00;
}

.tag-excelencia-rematricula{
    background-color: #FBAF1F;
}

.tag-excelencia-adimplencia{
    background-color: #DC3735;
}

.tag-excelencia-prospeccao{
    background-color: #B0CB51;
}