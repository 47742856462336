.header-wrapper {
    width: 100%;

    background-color: var(--white-color);

    position: fixed;

    left: 0;
    top: 0;

    height: 88px;

    z-index: 1001;

    box-shadow: 0px 4px 4px #9898980a;

    padding: 0 40px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-wrapper__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 170px;
        height: auto;
    }
}

.header-wrapper__profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    div img {
        margin: 0 !important;
    }
}

.profile__user {
    display: flex;
    flex-direction: column;

    h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #344453;
    }

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--primary-color);
    }
}
