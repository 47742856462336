.radio-turma-selected {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	min-width: 700px;
	width: 100%;
	height: 60px;
	background: var(--primary-color);
	border-radius: 8px;
	width: 100%;
}

.radio-turma-selected p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #FFFFFF;
	margin-bottom: 0px;
}

.radio-turma {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	min-width: 700px;
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #EBEFFC;
	box-sizing: border-box;
	border-radius: 8px;
}

.radio-turma p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #1F2832;
	margin-bottom: 0px;
}

.radio-group {
	margin-bottom: 15px;
}

.turma-select {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.turma-select .check-turma {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

.turma-select .input-box {
	width: 36% !important;
}

.turma-select .check-turma .box-selected {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	width: 175px;
	height: 40px;
	background: var(--blue-color-50);
	border-radius: 8px;
}

.turma-select .check-turma .box-selected p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: var(--primary-color);
	margin-bottom: 0px;
}

.turma-select .check-turma .box {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	width: 175px;
	height: 40px;
	background: rgba(52, 68, 83, 0.05);
	border-radius: 8px;
}

.turma-select .check-turma .box p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #1F2832;
	margin-bottom: 0px;
}

/*Index Turma*/

.box-title #turma h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
}

.box-title #turma h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #6A7C96;
}

#detalhe-turma {
	justify-content: flex-start !important;
}

#box-turma-detalhe {
	border: 0px !important;
	margin: 0px 10px 0px 0px !important;
}

#box-turma-detalhe p {
	margin-top: 0px !important;
}

#avatar-turma img {
	width: 120px !important;
	height: auto !important;
}

#porcentagem {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	width: 29%;
	min-height: 270px;
	height: 100%;
	background: #FFFFFF;
	border-radius: 8px;
}

#porcentagem .progress-circle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	position: relative;
	height: 230px;
}

#porcentagem .progress-circle .data {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 15px;
}

#porcentagem .progress-circle .data h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
	margin-bottom: 0px;
}

#porcentagem .progress-circle .data h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #485D73;
}

#porcentagem .lancamento {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 8px;
	width: 100%;
	height: 40px;
	background: rgba(236, 214, 15, 0.08);
	box-shadow: inset 3px 0px 0px #ECD60F;
	position: relative;
}

#porcentagem .lancamento p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #C5A74C;
	margin-bottom: 0px;
	margin-left: 10px;
}

.box-data-turma {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
}

.box-data-turma h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.box-data-turma .input-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8px;
	width: 100%;
	height: auto;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 15px;
}

.box-data-turma .input-box #avatar-skills {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box-data-turma .input-box #avatar-skills img {
	width: 75px;
	height: auto;
}

.box-data-turma h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	color: rgba(52, 68, 83, 0.5);
	width: 100%;
}

#text-area-observacoes {
	margin-bottom: 2px;
}

#turma {
	margin-left: 10px;
}

.container-rev {
	position: relative;
	background-color: white;
	width: 100%;
	height: 100%;
	padding: 15px;
	
	
}

.container-rev > .input-box-rev {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8px;
	width: 100%;
	height: auto;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 15px;
}

.input-box input, .input-box input:disabled, .ant-input {
	border: none;
	border-radius: 0;
	box-shadow: none;
}

.data-aluno-1 {
	background-color: var(--white);
	
	padding: 16px;
	
	border-radius: 8px;
}