.menu-documentos-box{
  left: calc(100vw - 90%);
  top: 130px;
  position: absolute;
  max-width: 290px;
  background-color: #FFFFFF;
  padding: 15px;
}

.menu-documentos-box h5{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #1F2832;
}

.menu-documentos-box p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #485D73;
  margin-bottom: 25px;
}

.menu-documentos-box .documento{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.menu-documentos-box .documento .check{
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #1EBE5E;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-documentos-box .documento .check-red{
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #FF4D4F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-documentos-box .documento p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #1F2832;
  margin-bottom: 0px;
  margin-left: 8px;
}
