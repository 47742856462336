.container-modal-payment-change {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal-change-payment {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;
	position: relative;
	width: 700px;
	height: auto;
	max-height: 650px;
	background: #ffffff;
	box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
	border-radius: 8px;
	.header-payment {
    margin-top: 5px;
		width: 100%;
		padding: 4px;
		h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 36px;
      color: #344453;
      margin-bottom: 0px;
      text-align: center;
		}
		p {
			text-align: center;
			margin: 6px 0px;
		}
	}
}
.container-payment {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: row;
	margin: 20px 0px;
	padding: 10px;
	width: 100%;
	gap: 10px;
}
.payment-item {
	text-align: center;
	cursor: pointer;
	width: 150px;
	background: #f5f5f5;
	height: 90px;
	border-radius: 8px;
	padding: 16px;
	margin-top: 10px;
	&:hover {
		transition: all 0.3s ease;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	}
}

.payment-item-select {
	@extend .payment-item;
	background-color: var(--blue-dark) !important;
	span {
		color: white !important;
	}
}
.footer-payment {
  gap: 10px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 10px;
  margin-top: 10px;
}
