.container-offer-page .box-calendar .calendar {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	display: grid;
}

.container-offer-page .box-calendar .calendar .box-in-calendar {
	border-radius: 8px;
	background-color: #ebeffc;
	width: 100%;
	min-height: 40px;
	height: 40px;
	max-height: 40px;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
}

.container-offer-page .box-calendar .calendar .box-in-calendar-almoco {
	border-radius: 8px;
	background-color: #cbd4ed;
	width: 100%;
	min-height: 60px;
	height: auto;
	padding: 10px;
}

.container-offer-page .box-calendar .calendar .box-in-calendar p,
.container-offer-page .box-calendar .calendar .box-in-calendar-almoco p {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #1a4da1;
}

.box-space-grid-17 {
	z-index: 17;
}

.box-space-grid-16 {
	z-index: 16;
}

.box-space-grid-15 {
	z-index: 15;
}

.box-space-grid-14 {
	z-index: 14;
}

.box-space-grid-13 {
	z-index: 13;
}

.box-space-grid-12 {
	z-index: 12;
}

.box-space-grid-11 {
	z-index: 11;
}

.box-space-grid-10 {
	z-index: 10;
}

.box-space-grid-9 {
	z-index: 9;
}

.box-space-grid-8 {
	z-index: 8;
}

.box-space-grid-7 {
	z-index: 7;
}

.box-space-grid-6 {
	z-index: 6;
}

.box-space-grid-5 {
	z-index: 5;
}

.box-space-grid-4 {
	z-index: 4;
}

.box-space-grid-3 {
	z-index: 3;
}

.box-space-grid-2 {
	z-index: 2;
}

.box-space-grid-1 {
	z-index: 1;
}

.box-space-grid-0 {
	z-index: 0;
}