#revisao-grupo {
  .menu-box-revisao-float {
    left: 15%;
    top: 28%;
    position: absolute;
    max-width: 290px;
  }

  .menu-box-revisao-float .bloco-1 h4 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #6a7c96;
    margin-bottom: 10px;
  }

  .menu-box-revisao-float .bloco-1 h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    color: #344453;
    display: flex;
    flex-wrap: wrap;
    text-overflow: clip;
    word-wrap: break-word;
  }

  .menu-box-revisao-float .bloco-1 button.adicionar,
  .menu-box-revisao-float .bloco-2 .documentos button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    height: 40px;
    background: #895cf2;
    border-radius: 4px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }

  .menu-box-revisao-float .bloco-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 155px;
    background: #ffffff;
    box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
    border-radius: 8px;
  }

  .menu-box-revisao-float .bloco-2 .franja {
    width: 9px;
    height: 100%;
    background: #e8d31a;
    border-radius: 8px 0px 0px 8px;
  }

  .menu-box-revisao-float .bloco-2 .documentos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    height: 100%;
  }

  .menu-box-revisao-float .bloco-2 .documentos .descricao {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
  }

  .menu-box-revisao-float .bloco-2 .documentos .descricao h6 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #344453;
  }

  .menu-box-revisao-float .bloco-2 .documentos .descricao span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(52, 68, 83, 0.5);
    margin-bottom: 7px;
  }

  #salvar {
    margin-top: 25px  !important;
  }
}
