#header {
  position: relative;
  /* width: 220px; */
  padding: 24px 8px 24px 8px;
  /* background-color: transparent; */
  top: 0px;
  left: 0px;
  background-color: var(--white-color);
  min-height: 100vh;
  max-height: 100vh;
  /* height: 100%; */
  // z-index: 3;
}

#header .pro-sidebar {
  height: calc(100vh - 50px);
  z-index: 100 !important;
  background-color: #344453;
  color: var(--color-happy);
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: var(--white-color);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.pro-sidebar-layout::-webkit-scrollbar {
  display: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext {
  p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;

    img {
      max-height: 56px;
      align-self: center;
    }

    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    cursor: pointer;

  }

}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  /* background: rgba(26, 77, 162, 0.15); */
  background: transparent;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul>.pro-inner-item {
  color: var(--color-happy);
  margin: 5px 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: #0c306c;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #0c306c !important;
}


/* submenu container ativo */
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: #b6c0d3;
  border-radius: 16px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .pro-sub-menu .pro-inner-list-item {
  margin-left: 20px;
  margin-bottom: 10px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .pro-sub-menu .pro-inner-list-item .pro-menu-item:hover {
  background-color: #faf39c;
  color: white;
  border-radius: 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .pro-sub-menu .pro-inner-list-item .pro-menu-item:hover .pro-item-content {
  color:var(--color-happy)!important;
}

#header .pro-sidebar-inner .pro-sidebar-layout .pro-sub-menu .active {
  background: transparent;
  border-radius: 16px;

  .pro-item-content {
    color: #0c306c;
    font-weight: bold;
  }
}

#header .logo {
  padding: 20px;
}

// @media only screen and (max-width: 720px) {
//   html {
//     overflow: hidden;
//   }
// }

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 0px solid !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 8px 35px 8px 18px !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 0 !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  border-top: 0px solid !important;
}

img.avatar {
  margin: 17px 8px 20px 8px;
  width: 50px;
  height: auto;
}

.box-avatar h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0c306c;
  margin-bottom: 2px;
}

.box-avatar p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
  margin-bottom: 2px;
}

.pro-sidebar .pro-menu .pro-menu-item:hover {
  background: #f1df43;
  border-radius: 16px;
}

.pro-sidebar .pro-menu .pro-menu-item:hover span.pro-item-content {
  color: var(--color-happy);
}

#header .pro-menu-item.active span.pro-item-content {
  color:var(--color-happy) !important;
}


.pro-sidebar .pro-menu .pro-menu-item.item-danger:hover {
  background: #f1df43;
  border-radius: 16px;
}

.pro-sidebar .pro-menu .pro-menu-item.item-danger:hover span.pro-item-content {
  color:#0c306c !important;
}

.pro-sidebar .pro-menu .pro-menu-item.item-avatar:hover {
  background: #f1df43;
  border-radius: 16px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext:hover {
  cursor: pointer;

}


.chamado {
  color: #3f5b8b !important;
}

.chamado:hover {
  color: #0c306c !important;
}

.pro-menu-item .pro-sub-menu .open .pro-inner-item {
  background: #ffe602;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: none;
}

.pro-sidebar .pro-menu .pro-sub-menu .pro-inner-list-item:has(.active) {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu .pro-sub-menu>.pro-sidebar .pro-menu .pro-sub-menu .pro-inner-list-item:has(.active) {
  background-color: transparent !important;
  
}


.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  background: rgba(255, 255, 255, 0.98);
  // background: #fefefe;
  padding-left: 5px !important;
}
