.split-metropole-container {
  display: flex;
  justify-content: flex-end;

  width: 100%;

  padding: 130px 104px;

  &__inputs-container {
    width: 100%;

    padding: 24px;

    border-radius: 16px;

    background-color: #fff;
  }
}

.split-metropole-container__inputs-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 65%;
}

.inputs-buttons-container__title-close-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #1f2832;
  }
}

.title-close-button__close-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;

  padding: 8px !important;

  width: 40px !important;
  height: 40px !important;

  background: #ebeffc !important;

  border-radius: 8px !important;

  color: #344453 !important;
}

.inputs-buttons-container__inputs-container {
  width: 100%;

  padding: 24px;

  border-radius: 8px;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inputs-container__split-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-size: 18px;
    line-height: 32px;
    color: #1f2832;
  }
}

.split-section__input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input {
    width: 100%;
    height: 40px;

    padding: 8px;

    border: 1px solid #ebeffc;
    border-radius: 8px;

    font-size: 16px;
    line-height: 24px;
    color: #1f2832;
  }
}

.split-section__inputs-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
}

.split-section__divisor {
  height: 1px;
  width: 100%;

  background: #ebeffc;
}

.inputs-buttons-container__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}