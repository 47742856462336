.menu-sidebar-and-container {
	width: 100vw;
	display: flex;

	background-color: var(--background-color);
}

.menu-sidebar-and-container__children-wrapper {
	min-height: 100vh;
	height: auto;

	width: 100%;

	padding: 56px;

	display: flex;
	flex-direction: column;
	gap: 32px;
}
