@font-face {
    font-family: 'KobelBold1-1';
    src: url('../../assets/fonts/Kobe1.1-Black.otf');
}

.container_pagamento{
    background-color: #FFF;
      width: 70%;
      height: 881px;
      display: flex;
      justify-content: center;    
      p{
        font-size: 1.3rem;
        margin: 0.5rem 0;
        color: #0C306C;
        font-family: 'KobelBold1-1', sans-serif;
        text-align: center;
    }  
    a:hover{
      text-decoration: underline;
    }
  }
  .container_any{
   min-width: 70%;
   text-align: center;
   display: flex;
   align-items: center;
   flex-direction: column; 
   transform: translateY(5%);
   img{
    margin: 0 0 1rem 0;
   }
   button{
        margin: 0.5rem 0;
         background-color: #0C306C;
         font-family: 'KobelBold1-1', sans-serif;
        color: #fff;
        padding: 5px;
   }
   p{
    font-size: 1.3rem;
    margin: 0.35rem 0;
    color: #0C306C;
    font-family: 'KobelBold1-1', sans-serif;
    text-align: center;
}  
  }
.container_boleto{
  width: 900px;
  height: 40%; 
}

@media screen and (max-width: 968px){
  .container_pagamento{
    background-color: transparent;
  }
  .container_any button{
    margin: 0 0 2rem 0;
  }
  .container_pagamento p{
    font-size: 1.1rem;
  }
  .container_any button{
    margin: 0.5rem 0;
  }
  .container_boleto{
      width: 400px;
  }
}