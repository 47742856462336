#taxas{
  width: 15%;
}

#taxas span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6A7C96;
}

.tabela .registro-user .user .nome .estado-user .dot-orange{
  width: 8px;
  height: 8px;
  background-color:  #F59300;
  border-radius: 8px;
  margin-right: 7px;
}

.tabela .registro-user .user .nome .estado-user p.standby{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #F59300;

}

/*Estilos step-1*/

.user-row h3#taxas-h3{
  margin-bottom: 8px;
  font-size:1.2rem;
}

.user-row h6#taxas-h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485D73;
  margin-bottom: 16px;
}

.user-row .input-box input#taxa{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485D73;
}

.user-row .input-box#box-taxas{
  margin-bottom: 0px !important;
}

.user-row .inputs-box#box-tax{
  margin-top: 15px;
  margin-bottom: 25px; 
}

.user-row .input-box .input-price{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.user-row .input-box .input-price span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(52, 68, 83, 0.5);
  margin-right: 8px !important;
}

.user-row .ant-radio-group .boxes{
  justify-content: space-between;
  width: 100%;
}

#price-info, #data-info{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
}

#price-info span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
}

#price-info p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 0px;
  margin-left: 5px;
}

#data-info .box-data{
  margin-right: 10px;
}

#data-info .box-data h5{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 3px;
}

#data-info .box-data p{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 3px;
}

#input-taxa{
  width: 22%;
  min-width: 210px;
  max-width: 215px;
}