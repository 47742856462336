.box-pessoa{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 98%;
  height: auto;
  background: #FFFFFF;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.box-pessoa h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0px;
}

.box-pessoa h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52,68,83,0.5);
  margin-bottom: 10px;
}

.box-pessoa-selected{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 98%;
  height: auto;
  background: var(--primary-color);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.box-pessoa-selected h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.box-pessoa-selected h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255,255,255,0.5);
  margin-bottom: 10px;
}
