.relatorios-box-wrapper,.cards-dash  {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 12px;
}

@media (min-width: 640px) {
	.relatorios-box-wrapper,.cards-dash  {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media (min-width: 768px) {
	.relatorios-box-wrapper,.cards-dash  {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media (min-width: 1024px) {
	.relatorios-box-wrapper,.cards-dash  {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media (min-width: 1280px) {
	.relatorios-box-wrapper,.cards-dash  {
		grid-template-columns: repeat(5, minmax(0, 1fr));
	}
}

/**************| Configuração dos card contador |**************/
.box-header-contador-relatorio {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: max-content;
	margin: 20px auto 20px auto;
	gap: 10px;
}

.box-header-contador-relatorio .box-informe {
	width: 150px;
	height: 100px;
	border-radius: 10px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	flex: 1;
}

.box-header-contador-relatorio h1 {
	font-weight: 600;
	text-align: center;
	font-size: 0.8rem;
}

.ant-table-cell {
	cursor: pointer;
}
.cards-dash .box-relatorio {
	width: 100%;
	min-height: 150px;
	max-height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd; 
	border-radius: 8px;
	background-color: white;

	&:hover {
		border: 1px solid #c5cddc;
	}
  }