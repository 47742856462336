.modalContainer{
  position: fixed;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box-upload-material{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: relative;
  width: 776px;
  height: 656px;
  background: #FFFFFF;  
  box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
  border-radius: 8px;
}

.modal-box-upload-material h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
}

.modal-box-upload-material h6{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485D73;
  margin-bottom: 24px;
}

.modal-box-upload-material .inputModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 12px;
  width: 100%;
  height: 58px;
  background: #FFFFFF;
  border: 1px solid rgba(52, 68, 83, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px;
}

.modal-box-upload-material .inputModal span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #344453;
}

.modal-box-upload-material .vermelho {
  color: #FF0000 !important;
}

.modal-box-upload-material .inputModal input{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
  width: 100%;
}

.modal-box-upload-material .botoes{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.modal-box-upload-material .botoes .btn-cancel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 146px;
  height: 40px;
  background-color: transparent;
  border: 1.5px solid #EBEFFC;
  box-sizing: border-box;
  border-radius: 4px;
  color: #895CF2;
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.modal-box-upload-material .botoes .btn-make{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 146px;
  height: 40px;
  background: #895CF2;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.modal-box-upload-material .fechar{
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px; 
  width: 40px;
  height: 40px;
  background: #EBEFFC;
  border-radius: 8px;
}