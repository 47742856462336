.box-bloco-miniatura {
	width: 150px;
	border-radius: 4px;
	padding: 8px;
	background-color: transparent;
	gap: 3px;
	height: 120px;
}

.box-bloco-miniatura p {
	color: transparent !important;
}

.box-adicionarHora-miniatura {
	width: auto;
	border-radius: 4px;
	padding: 8px;
	gap: 3px;
	height: 100%;
	display: flex;
	align-items: center;
}

.card__miniatura {
	width: 150px;
	height: 120px;
	padding: 8px;
	border-radius: 4px;
	gap: 8px;
	margin-top: -5px !important;
	z-index: 1000;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.card__miniatura p {
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 13px !important;
	letter-spacing: 0 !important;
	color: #f3e5f5 !important;
}

.card__miniatura .box-sala p,
.card__miniatura .box-vaga p {
	font-family: Open Sans !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	line-height: 14px !important;
	letter-spacing: 0em !important;
	color: #f3e5f5 !important;
}

.card__miniatura .box-info {
	width: 100%;
	gap: 8px;
}

.card__miniatura .box-sala,
.card__miniatura .box-vaga {
	padding: 2px;
	border-radius: 2px;
}

.card__miniatura .box-sala {
	flex: 1;
}

.card__miniatura .box-vaga {
	width: auto;
}

.box-code-miniatura {
	background-color: #b1cb4e !important;
}

.box-code-miniatura .box-sala,
.box-code-miniatura .box-vaga {
	background-color: #bed952;
}

.box-money-miniatura {
	background-color: #fcb017 !important;
}

.box-money-miniatura .box-sala,
.box-money-miniatura .box-vaga {
	background-color: #f8bb43;
}

.box-speech-miniatura {
	background-color: #dd3736 !important;
}

.box-speech-miniatura .box-sala,
.box-speech-miniatura .box-vaga {
	background-color: #fb6c6c;
}

.box-english-miniatura {
	background-color: #f4a06c !important;
}

.box-english-miniatura .box-sala,
.box-english-miniatura .box-vaga {
	background-color: #f37021;
}

.box-onecode-miniatura {
	background-color: #434d1d !important;
}

.box-onecode-miniatura .box-sala,
.box-onecode-miniatura .box-vaga {
	background-color: #bed952;
}

.box-onecom-miniatura {
	background-color: #b35419 !important;
}

.box-onecom-miniatura .box-sala,
.box-onecom-miniatura .box-vaga {
	background-color: #733610;
}

.box-antigo-miniatura {
	background-color: #3d2b06 !important;
}

.box-antigo-miniatura .box-sala,
.box-antigo-miniatura .box-vaga {
	background-color: #7d570b;
}

.box-escolas-miniatura {
	background-color: #608ecc !important;
}

.box-escolas-miniatura .box-sala,
.box-escolas-miniatura .box-vaga {
	background-color: #1f94de;
}

.box-curta-miniatura {
	background-color: #00a2a6 !important;
}

.box-curta-miniatura .box-sala,
.box-curta-miniatura .box-vaga {
	background-color: #00838f;
}

.box-colonia-miniatura {
	background-color: #7a07b0 !important;
}

.box-colonia-miniatura .box-sala,
.box-colonia-miniatura .box-vaga {
	background-color: #512da8;
}

.box-cb-miniatura {
	width: 150px;
	height: 280px;
	background-color: #1f1cb7 !important;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.box-cb-miniatura .box-sala,
.box-cb-miniatura .box-vaga {
	background-color: #6462e0;
}

.box-selected-miniatura {
	background-color: #0c306c !important;
}

.box-selected-miniatura .box-sala,
.box-selected-miniatura .box-vaga {
	background-color: #1c6491;
}

.box-azul-disabled-miniatura {
	cursor: not-allowed !important;
	background-color: #1a4da190 !important;
}

.box-azul-disabled-miniatura .box-sala,
.box-azul-disabled-miniatura .box-vaga {
	background-color: rgba(255, 255, 255, 0.5);
}

.box-cinza {
	cursor: not-allowed !important;
	background-color: #717171 !important;
}

.box-cinza .box-sala,
.box-cinza .box-vaga {
	background-color: #8e8e8e !important;
}

.box-code-miniatura:hover,
.box-money-miniatura:hover,
.box-speech-miniatura:hover,
.box-english-miniatura:hover,
.box-onecode-miniatura:hover,
.box-onecom-miniatura:hover,
.box-antigo-miniatura:hover,
.box-escolas-miniatura:hover,
.box-curtas-miniatura:hover,
.box-colonia-miniatura:hover,
.box-selected-miniatura:hover {
	cursor: pointer;
	z-index: 1001;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.box-adicionarHora-miniatura:hover {
	cursor: pointer;
}
