#escola-grupos {
	.dashboard {
		position: relative;
		top: 0px;
		width: 100%;
		min-height: 100vh;
		height: auto;
		padding-right: 56px;
		padding-top: 93px;
		display: flex;
		flex-direction: column;
		background-color: var(--white-color);
	}

	.box-title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
	}

	.titles {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 0px solid #cbd4ed;
		padding: 0px 0px 10px 0px;
		margin-bottom: 8px;
	}

	.item {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		height: auto;
		border-radius: 0px;
		padding: 0px;
		background-color: transparent !important;
	}

	.tabela-body,
	.registro-user {
		padding: 0px;
	}
}

.filtros {
	align-items: flex-end !important;
}

.ant-col {
	width: 100% !important;
}

.searchbar-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1 1 0%;

	padding: 8px 12px !important;

	height: 40px;

	background: #ffffff;

	border-radius: 4px;
	border: 1px solid rgba(52, 68, 83, 0.05) !important;

	box-sizing: border-box;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
	border: 1px solid #6a7c96 !important;
}

.ant-input-affix-wrapper-focused {
	border: 1px solid #6a7c96 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 !important;
	padding-right: 12px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 0 !important;
}
