#escola-grupos {
  .dashboard {
    position: relative;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding-right: 56px;
    padding-top: 93px;
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
  }

  .box-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .titles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0px solid #cbd4ed;
    padding: 0px 0px 10px 0px;
    margin-bottom: 8px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 0px;
    padding: 0px;
    background-color: transparent !important;
  }

  .tabela-body, .registro-user {
    padding: 0px;
  }

  .box-title #turma h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 24px;
	color: #92a9cb;
	margin-bottom: 0px;
}

.box-title #turma h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 30px;
	letter-spacing: 0.5px;
	color: #344453;
	margin-bottom: 0px;
}

.boxes-financeiro {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 30px;
}

.boxes-financeiro .box-financeiro {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-width: 30%;
	margin-left: 0px !important;
}

.boxes-financeiro .box-financeiro .icon {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(30, 190, 94, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-receber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  background: #2196F333;
  border-radius: 8px;
  margin-right: 15px;
}


.boxes-financeiro .box-financeiro .icon-aberto {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(255, 151, 91, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-atraso {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(245, 147, 0, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-em-dia {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(30, 190, 94, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-vencido {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(235, 213, 20, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-cancelado {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(255, 77, 79, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}

.boxes-financeiro .box-financeiro .icon-estornado {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: rgba(63, 81, 181, 0.2);
	border-radius: 8px;
	margin-right: 15px;
}


.boxes-financeiro .box-financeiro .pago h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 0.9rem;
	line-height: 26px;
	color: #344453;
	margin-bottom: 0px;
}

.boxes-financeiro .box-financeiro .pago h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 24px;
	color: #92a9cb;
	margin-bottom: 0px;
	white-space: nowrap
}

.tabela .registro-user#registro-financeiro {
	padding-top: 17px;
	margin-bottom: 0px;
}

.tabela .registro-user#registro-financeiro:hover {
	background-color: rgba(203, 212, 237, 0.2);
	padding-top: 17px;
	margin-bottom: 0px;
}

.tabela .registro-user#registro-financeiro-selected {
	padding-top: 17px;
	margin-bottom: 0px;
	background-color: rgba(203, 212, 237, 0.2);
}

.check-left {
	margin-left: 10px;
}

.filtros-botoes-financeiro {
	display: none;
}

.filtros-botoes-financeiro-active {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 20px;
}

#gestor-color p {
	color: var(--primary-color);
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-dropdown {
	cursor: pointer;
}

.input-dashboard
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	background: #f5f5f9 !important;
}

}
