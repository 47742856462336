.box-faq {
    max-width: 1500px;
    padding: 50px;
    margin: 0 auto 50px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box-faq-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50px;
    margin: 20px auto 20px auto;
}

.input-faq-search {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.faq-content{
    display: flex!important;
    justify-content: center!important;
}

.faq-image{
    font-size: 20px!important;
}