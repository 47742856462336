#campanha-box {
	justify-content: flex-start;
}

#campanhas-info {
	margin-right: 0px;
}

#curso-campanhas {
	width: 50%;
}

#curso-campanhas h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
}

#curso-campanhas #user-franquias-revisao {
	margin-left: 15px;
}

#check-revisao{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

#check-wrap{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

#box-radios-wrap{
	margin-bottom:30px;
	min-width: 33.2%;
	max-width: 33.3%;
}
