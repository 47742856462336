#alunos,
#edade {
	width: 10%;
}

#alunos p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #344453;
}

#aluno-responsavel {
	width: 30%;
}

#aluno-responsavel p {
	font-style: normal;
	font-weight: 600;
	font-size: 0.8rem;
	line-height: 20px;
	color: #895cf2;
}

.doc-aluno {
	flex: 1;
	text-align: right;
	margin-right: 20px;
}

.aluno {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
	width: 100%;
}

.aluno .avatar {
	max-width: 120px;
	width: 115px;
	height: 115px;
	border-radius: 60px;
	background-color: #cbd4ed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.aluno .avatar .camera {
	background-color: var(--primary-color);
	width: 40px;
	height: 40px;
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: -5px;
	right: -5px;
}

.user-row .inputs-box .input-box#box-aluno {
	margin-bottom: 0;
}

p#sexo {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	color: #1f2832;
	margin-bottom: 15px;
}

span#ob {
	color: red;
}

.aluno-responsavel {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 8px 8px 8px 8px;
	margin-bottom: 16px;
}

.aluno-responsavel .title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	width: 100%;
	height: 45px;
	background: #ebeffc;
	border-radius: 8px 8px 0 0;
}

.aluno-responsavel .title h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #6a7c96;
	margin-bottom: 0;
}

.aluno-responsavel .dados {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 16px 16px 16px;
	position: static;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 0 0 8px 8px;
}

.aluno-responsavel .dados h4 {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
	margin: 0 0 16px 0;
}

.aluno-responsavel .dados .text-responsavel {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 5px;
}

.aluno-responsavel .dados .text-responsavel h5 {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
	margin-bottom: 0;
	margin-right: 5px;
}

.aluno-responsavel .title .ant-btn {
	background-color: transparent !important;
}

#row-aluno .box-documentos {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	width: 100%;
	margin-bottom: 24px;
}

#row-aluno .franja {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #f59300;
}

#row-aluno .documentos {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: 100%;
}

#row-aluno .descricao {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 10px;
	position: relative;
}

#row-aluno .descricao h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #1f2832;
}

#row-aluno .descricao span {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: rgba(52, 68, 83, 0.5);
}

#row-aluno .dot {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
}

#row-aluno .dot-orange {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f59300;
	margin-right: 6px;
	margin-top: 5px;
}

#row-aluno .dot span {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #f59300;
}

#row-aluno .button-upload {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 64px;
	box-sizing: border-box;
	border-radius: 2px;
	background-color: transparent;
	color: var(--primary-color);
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

#row-aluno .icon-upload {
	margin-right: 5px;
}

#row-aluno .franja-verde {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #1ebe5e;
}

#row-aluno .dot-verde {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #1ebe5e;
	margin-right: 6px;
	margin-top: 5px;
}

#row-aluno .dot p {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
}

#row-aluno .line-docs {
	border: 0.5px solid #cbd4ed;
	width: 100%;
	margin-top: 10px;
}

#row-aluno .arquivos-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 16px;
	width: 100%;
}

#row-aluno .arquivos-box span {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

#row-aluno .arquivos-box .arquivos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 26px;
	padding-bottom: 18px;
	padding-left: 10px;
	padding-right: 10px;
}

#row-aluno .arquivos-box .arquivos .nome {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#row-aluno .arquivos-box .arquivos .check-doc {
	height: 20px;
	width: 20px;
	background-color: #1ebe5e;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Docs Vermelho */
#row-aluno .franja-vermelho {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #ff4d4f;
}

#row-aluno .dot-red {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	position: absolute;
	right: 5px;
	top: 5px;
}

#row-aluno .dot-vermelho {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #ff4d4f;
	margin-right: 6px;
	margin-top: 5px;
}

#row-aluno .dot-red h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #ff4d4f;
}

#row-aluno .arquivos-box .arquivos .check-doc-red {
	height: 20px;
	width: 20px;
	background-color: #ff4d4f;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
}

#row-aluno .arquivos-box .arquivos .check-upload {
	flex-direction: row;
	display: flex;
	justify-self: flex-end;
	align-items: center;
}

#row-aluno .historico {
	margin-top: 20px;
	margin-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

#row-aluno .historico button {
	background-color: transparent;
}

#row-aluno .historico span {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: var(--primary-color);
}

.box-aluno {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 15px;
	width: 100%;
}

.box-aluno .avatar {
	margin-right: 20px;
	max-width: 120px;
	border-radius: 60px;
	background-color: #cbd4ed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}

.documentos-aluno {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #ebeffc;
	padding-bottom: 12px;
	padding-top: 12px;
}

.documentos-aluno .estado {
	margin-top: 0;
	max-width: 85%;
	overflow: hidden;
}

.documentos-aluno .check-doc {
	height: 16px;
	width: 16px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	background-color: #1ebe5e;
}

.documentos-aluno p {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
	margin-bottom: 0;
}

.documentos-aluno .detalhes {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px;
	width: 32px;
	height: 32px;
	background: rgba(203, 212, 237, 0.5);
	border-radius: 16px;
}

.documentos-aluno .check-doc-red {
	background-color: #ff4d4f;
	height: 16px;
	width: 16px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.resposavel-revisao {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 8px;
}

.resposavel-revisao .dados-franquia {
	justify-content: space-between !important;
	width: 100%;
}

.resposavel-revisao .dados-franquia .cpf {
	width: 48%;
	margin-right: 0 !important;
}

.resposavel-revisao a p.dethales-aluno {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: var(--primary-color);
}

#aluno-detalhe-row {
	justify-content: center;
	left: 0;
}

#row-aluno-box {
	width: 900px;
}

#aluno-detalhe-row .box-desconto {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 24px;
	width: 100%;
	height: auto;
	background: #895cf2;
	border-radius: 4px;
	margin-bottom: 15px;
}

#aluno-detalhe-row .box-desconto h5 {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
}

#aluno-detalhe-row .box-desconto p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-bottom: 0;
}

#row-aluno-box .box-cursos {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 4px;
	margin-bottom: 20px;
}

#row-aluno-box .box-cursos .title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	width: 100%;
}

#row-aluno-box .box-cursos .title h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
}

#row-aluno-box .box-cursos .matricular-rematricular-buttons {
	display: flex;
	align-items: center;
	gap: 16px;
}

#row-aluno-box .box-cursos .boxes-cursos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 25px;
}

#row-aluno-box .box-cursos .boxes-cursos .curso {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	min-width: 23%;
	width: 220px;
	max-width: 24%;
	margin-bottom: 20px;
}

#row-aluno-box .box-cursos .boxes-cursos .curso img {
	margin-right: 10px;
}

#row-aluno-box .box-cursos .boxes-cursos .curso .data h5 {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin-bottom: 4px;
}

#row-aluno-box .box-cursos .boxes-cursos .curso .data p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 0;
}

#row-aluno-box .box-data-aluno {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
	margin-top: 20px;
	gap: 10px;
}

#row-aluno-box .box-data-aluno .data-aluno {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 24px;
	height: 200px;
	min-height: 200px;
	background: #ffffff;
	border-radius: 4px;
	width: 100%;
	position: relative;
}

#row-aluno-box .box-data-aluno .data-aluno img {
	width: 150px;
	height: auto;
	margin-right: 20px;
}

#row-aluno-box .box-data-aluno .data-aluno .data {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 100%;
}

#row-aluno-box .box-data-aluno .data-aluno .data .head h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	margin-bottom: 3px;
}

#row-aluno-box .box-data-aluno .data-aluno .data .head h5 {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 3px;
}

#row-aluno-box .box-data-aluno .data-aluno .data .footer h5 {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 5px;
}

#row-aluno-box .box-data-aluno .data-aluno .data .footer .email {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#row-aluno-box .box-data-aluno .data-aluno .data .footer .email p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 2px;
	margin-right: 10px;
}

#row-aluno-box .box-data-aluno .data-aluno .box-dot {
	position: absolute;
	right: 24px;
	top: 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#row-aluno-box .box-data-aluno .data-aluno .box-dot .dot {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #1ebe5e;
	margin-right: 5px;
}

#row-aluno-box .box-data-aluno .data-aluno .box-dot .dot-vermelho {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #ff4d4f;
	margin-right: 5px;
}

#row-aluno-box .box-data-aluno .data-aluno .box-dot p {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
	margin-bottom: 0;
}

#row-aluno-box .box-data-aluno .coins {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	background: #ffffff;
	border-radius: 4px;
	width: 30%;
	height: 200px;
	min-height: 200px;
	position: relative;
}

#row-aluno-box .box-data-aluno .coins img {
	position: absolute;
	right: 40px;
	top: 40px;
}

#row-aluno-box .box-data-aluno .coins h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	margin-bottom: 5px;
}

#row-aluno-box .box-data-aluno .coins p {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 3px;
}

#row-aluno-box .box-data-aluno .coins .box-coins {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 10px;
}

#row-aluno-box .box-data-aluno .coins .box-coins .coin {
	width: 40px;
	height: 40px;
	background: #c4c4c4;
	opacity: 0.48;
	border-radius: 20px;
	margin-bottom: 10px;
}

#row-aluno-box .head-botoes {
	display: flex;
	align-items: center;
	gap: 16px;
	
	margin-bottom: 10px;
}

#row-aluno-box .head-botoes h5 {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #485d73;
	margin-bottom: 15px;
	width: 48%;
}

#row-aluno-box .box-contrato,
#box-contrato-detalhe {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 88px;
	background: #895cf2;
	border-radius: 8px;
}

#row-aluno-box .box-contrato h6,
#box-contrato-detalhe h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}

#row-aluno-box h6#contrato,
h6#contrato {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-top: 10px;
	margin-bottom: 10px;
}

#box-border {
	border: 1px solid rgba(203, 212, 237, 0.5);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 25px;
	position: relative;
}

#button-detalhe-aluno {
	right: 10px;
	top: 10px;
}

#logo-pagamento {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
}

#logo-pagamento h5 {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #344453;
}

#logo-pagamento img {
	width: 100px;
	height: auto;
}

#logo-pagamento h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 0;
}

#logo-pagamento h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #344453;
	margin-bottom: 0;
}

#logo-pagamento h6#vermelho {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #ff4d4f;
	margin-bottom: 0;
}

#logo-pagamento h2,
#logo-pagamento h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
	margin-bottom: 0;
}

#turma-detalhe {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 20px;
}

#turma-detalhe .avatar-turma {
	margin-right: 25px;
}

#turma-detalhe .avatar-turma img {
	width: 100px;
	height: auto;
	margin-bottom: 15px;
}

#turma-detalhe .data h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 1.3rem;
	line-height: 36px;
	color: #344453;
	margin-bottom: 5px;
}

#turma-detalhe .data p {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #6a7c96;
	margin-bottom: 5px;
}

#turma-detalhe .data .dats {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	width: 55%;
	margin-bottom: 20px;
	margin-top: 20px;
}

#turma-detalhe .data .dats .dat {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-right: 15px;
	margin-top: 3px;
	margin-bottom: 3px;
}

#turma-detalhe .data .dats .dat p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: #6a7c96;
	margin-bottom: 0;
	margin-left: 5px;
}

#turma-detalhe .boxes {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow-x: auto !important;
	overflow-y: hidden !important;
	margin: 10px 0 10px 0;
	max-width: 600px;
}

#turma-detalhe .boxes .box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 35px;
	background: var(--blue-color-20);
	border-radius: 4px;
	padding: 8px;
	width: auto;
	min-width: 130px;
	margin-right: 5px;
	margin-left: 0;
}

#turma-detalhe .boxes .box h6 {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: var(--primary-color);
	margin-bottom: 0;
	margin-right: 10px;
}

#turma-detalhe .boxes .box p {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: var(--blue-ligth);
	margin-bottom: 0;
	margin-top: 0;
}

#turma-detalhe .box-dot {
	position: absolute;
	right: 24px;
	top: 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#turma-detalhe .box-dot .dot {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #1ebe5e;
	margin-right: 5px;
}

#turma-detalhe .box-dot p {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
	margin-bottom: 0;
}

#dot-2 {
	top: 50px !important;
	right: 0 !important;
}

.title-upgrade {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly !important;
}

.input-upgrade {
	display: flex;
	flex-direction: row;
}

.botoes-upgrade {
	display: flex;
	flex-direction: row;
}

.botoes-upgrade button {
	margin-right: 10px;
	margin-bottom: 1%;
}

.tabela .registro-user-upgrade {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
}

.select-upgrade {
	width: 100%;
	padding-left: 10px;
	height: 30px;
}

.status-tranferencia-turma {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.buttons {
	width: 65%;
}

.infos {
	width: 35%;
	
	p {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: rgba(52, 68, 83, 0.5);
		text-align: left;
	}
}

.container-btn-infos {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	
}

.two-lines {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.responsavel__ativo .ant-switch-checked {
	background-color: #1ebe5e !important;
}

.responsavel__inativo .ant-switch {
	background-color: #ff4d4f !important;
}