.menu{
  width: 104px;
  min-height: 100vh;
  height: auto;
  position: fixed;
  max-height: 100vh;
  top:0px;
  left: 0px;
  background-color: #FFFFFF;
  Padding: 0px 8px 0px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
}

.menu img{
  margin: 30px 8px 50px 8px;
}

.item-selected{
  width: 88px;
  height: 40px;
  background-color: #EBEFFCB3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 7px;
}

.item-selected span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #895CF2;
}

.item{
  width: 88px;
  height: 40px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 7px;
}

.item span{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #92A9CB;
}

.item img{
  width: 20px;
  height: 20px;
}

.item:hover{
  width: 88px;
  height: 40px;
  background-color: #EBEFFCB3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 7px;
}

.menu img.avatar{
  margin: 0px 8px 20px 8px;
  width: 50px;
  height: auto;
}

.menu img.footer{
  margin: 20px 0px 0px 0px;
}
