.modalContainer{
    position: fixed;
    top:0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-user-stemplay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 540px;
    height: auto;
    background: #FFFFFF;  
    box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
    border-radius: 8px;
  }
  
  .modal-user-stemplay h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    color: #344453;
    margin:30px 30px 16px 30px;
    text-align: center;
    margin-top: 20px !important;
  }
  
  .modal-user-stemplay h6{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(52, 68, 83, 0.5);
    margin:0px 30px 12px 30px;
    margin-top: 20px !important;
  }
  
  .modal-user-stemplay .line-stemplay{
    border: 1px solid #EBEFFC;
    width: 100%;
    margin-top:20px;
    margin-bottom: 20px;
  }
  
  .modal-user-stemplay .botoes-stemplay{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding:0px 16px 0px 16px;
  }
  