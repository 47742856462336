.autonomia-row {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	min-height: 100vh;
}

.autonomia-row .boxes-autonomia {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.autonomia-row .boxes-autonomia .box-autonomia {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	width: 50%;
	height: auto;
	background: #ffffff;
	border-radius: 8px;
}

.autonomia-row .title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.autonomia-row .line {
	border: 1px solid #ebeffc;
	width: 100%;
}

.autonomia-row .title h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	margin-bottom: 16px;
}

.autonomia-row h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
	margin-top: 10px;
}

.autonomia-row .input-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	height: 40px;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 16px;
	width: 100%;
}

.autonomia-row .botoes#botoes-auto {
	justify-content: flex-start;
}

.autonomia-row h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
	margin-bottom: 5px;
	margin-top: 10px;
}

.autonomia-row p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
}
