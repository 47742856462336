.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.box-stemplay{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 0px;
    width: 300px;
    height: 180px;
    background: #FFFFFF;
    border: 1px solid rgba(203, 212, 237, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .box-stemplay .description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    min-height: 130px;
    margin-top: 15px;
  }
  
/* Animação */
.title {
  transition: .3s;
  position: relative;
}

.title:after {
  transform: translate3d(0, 50px, 0);
  position: absolute;
}

.title:hover:after{
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
