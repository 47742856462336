.alunos-top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.filtros {
  margin-bottom: 0 !important;
}

.searchbar-input {
  width: 100%;

  background-color: var(--white-color) !important;
  border: 1px solid #edeff4 !important;

  &:hover,
  &:focus {
    border: 1px solid #c5cddc !important;
  }

  .ant-input {
    background-color: var(--white-color) !important;

    &:focus {
      border: none !important;
    }
  }
}

.filtro-estado {
  background-color: var(--white-color) !important;

  border: 1px solid #edeff4 !important;
}

.students-table {
  padding: 12px;

  width: 100%;

  display: flex;
  flex-direction: column;
}

.students-table__students-header {
  width: 100%;

  border-bottom: 1px solid #ebeffc;

  display: flex;
}

.students-header__column-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: #6a7c96;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;

  padding: 8px 0;

  cursor: pointer;

  &:hover {
    color: #92a9cb;
  }
}

.column-header__sort-arrow {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    color: #92a9cb;
  }
}

.students-table__row-user {
  display: flex;
  align-items: center;

  width: 100%;
  border-bottom: 1px solid #ebeffc;
  padding-bottom: 17px;
  margin-bottom: 0pxpx;
  padding-top: 17px;
}

.row-user__user-cell {
  display: flex;
  align-items: center;
  gap: 12px;

  div > img {
    margin: 0 !important;

    width: 48px !important;
    height: 48px !important;
  }
}

.user-cell__student-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #344453;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 200px;
}

.user-cell__student-status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.student-status__dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #92a9cb;
}

.green-dot {
  background-color: var(--green-color);
}

.red-dot {
  background-color: var(--red-color);
}

.student-status__status-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.active {
  color: var(--green-color);
}

.inactive {
  color: var(--red-color);
}

.row-user__simple-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #344453;
}

.row-user__parent-cell {
  color: var(--blue-ligth);
}

.row-user__action-cell {
  width: 36px;
  height: 36px;

  border-radius: 50%;

  background-color: rgba(203, 212, 237, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.action-cell__action-icon {
  color: var(--blue-ligth);
  font-weight: 600;
}

.row-user__action-cell,
.action-cell__action-icon {
  transition-property: color, background-color, border-color,
  text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.row-user__action-cell:hover {
  background-color: rgba(203, 212, 237, 0.7);
}

.row-user__action-cell:hover .action-cell__action-icon {
  color: var(--blue-dark);
}

.dados .title-and-edit-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
}

.box-revisao .dados h4 {
  margin-bottom: 0 !important;
}