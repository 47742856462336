.space1 {
	width: 38%;
}

.franquia-row h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 36px;
	color: #353f49;
	margin-bottom: 24px;
}

.input-franquia,
.select-franquia {
	width: 100%;
	height: auto;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 6px;
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
}

.input-franquia span,
.select-franquia span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
	margin-bottom: 3px;
}

.input-franquia input {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #6a7c96;
}

.select-franquia select {
	width: 100%;
	max-width: 100%;
	border: 1px solid var(--select-border);
	border-radius: 0.25em;
	cursor: pointer;
	line-height: 1.1;
	background-color: #fff;
	background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
	color: #485d73;
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
}

.botoes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.voltar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	position: static;
	height: 40px;
	background: #ebeffc;
	border-radius: 4px;
	width: 345px;
	margin-right: 10px;
	cursor: pointer;
	opacity: 0.92;
	transition: 0.3s;
}

.voltar:hover {
	background-color: #ebeff5;
}

.voltar span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #895cf2;
}

.proximo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 345px;
	height: 40px;
	background: #895cf2;
	border-radius: 4px;
	cursor: pointer;
	opacity: 0.92;
	transition: 0.3s;
}

.proximo:hover {
	background-color: #895cf2e8;
}

.proximo span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #ffffff;
}

.fechar {
	position: absolute;
	right: 108px;
	top: 124px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 40px;
	height: 40px;
	background: #ebeffc;
	border-radius: 8px;
}

.box-cidade {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box-cidade .select-franquia {
	width: 48%;
}

.box-complemento {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box-complemento .input-franquia {
	width: 79%;
}

.box-complemento .input-franquia .box2 {
	width: 16%;
}

.input-franquia-data {
	width: 100%;
	height: auto;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 24px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.input-franquia-data input {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: rgba(52, 68, 83, 0.5);
	width: 630px;
}

.input-franquia-data span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
	margin-bottom: 3px;
}

.box-data {
	display: flex;
	flex-direction: column;
}

.box-cards {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
}

.box-cards .card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	width: 344px;
	height: 294px;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	position: relative;
}

.box-cards .card h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #344453;
}

.box-cards .card img {
	margin-top: 24px;
	margin-bottom: 16px;
}

.box-cards .card p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #6a7c96;
	margin-bottom: 24px;
}

.box-cards .card .proximo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 313px;
	height: 40px;
	background: #895cf2;
	border-radius: 4px;
	cursor: pointer;
	opacity: 0.92;
	transition: 0.3s;
}

.box-cards .card .proximo:hover {
	background-color: #895cf2e8;
}

.franquia-responsavel h3 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	margin-bottom: 8px;
}

.franquia-responsavel .line {
	border: 1px solid #cbd4ed;
	height: 0px;
	margin-bottom: 24px;
}

.franquia-responsavel h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
	margin-bottom: 16px;
}

.franquia-responsavel .busca,
.box-revisao .dados .busca {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 12px;
	width: 100%;
	height: 40px;
	background: #ffffff;
	border-radius: 4px;
}

.busca input {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
	margin-left: 6px;
}

.franquia-responsavel .box {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	/* height: 92px; */
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 16px;
}

.franquia-responsavel .circle,
.box-revisao .dados .dados-franquia .box-selected .circle {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	background: #ffffff;
	border: 1px solid #cccccc;
	box-sizing: border-box;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.box .box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.box-text h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #1f2832;
}

.franquia-responsavel .botoes {
	margin-top: 24px;
}

.franquia-responsavel .botoes button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;
	position: static;
	width: 310px;
	height: 40px;
	background: #ebeffc;
	border-radius: 4px;
	margin-bottom: 24px;
	margin-top: 24px;
}

.franquia-responsavel .cancelar {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #895cf2;
}

.franquia-responsavel .confirmar {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #92a9cb;
}

.botao-voltar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	position: absolute;
	width: 105px;
	height: 40px;
	left: calc(50% - 105px / 2 - 546px);
	top: 116px;
	background: #ebeffc;
	border-radius: 4px;
}

.botao-voltar span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #895cf2;
	margin-left: 5px;
}

.franquia-responsavel .box-detalhes {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: 208px;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 16px;
}

.franquia-responsavel .box-detalhes .box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.franquia-responsavel .box-detalhes .box-dados {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.franquia-responsavel .box-detalhes .box-dados .dados {
	margin-right: 32px;
}

.franquia-responsavel .box-detalhes .box-dados h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #485d73;
}

.franquia-responsavel .box-detalhes .box-dados span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.franquia-responsavel .box-selected,
.box-revisao .dados .dados-franquia .box-selected {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: 92px;
	background: #895cf2;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 16px;
}

.franquia-responsavel .box-selected .box-text,
.box-revisao .dados .dados-franquia .box-selected .box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.franquia-responsavel .box-selected h4,
.box-revisao .dados .dados-franquia .box-selected h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #ffffff;
}

.franquia-responsavel .box-selected span,
.box-revisao .dados .dados-franquia .box-selected span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
}

.circle-selected {
	height: 12px;
	width: 12px;
	background-color: #895cf2;
	border-radius: 12px;
}

/* Step 5 */

.alterar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 310px;
	height: 40px;
	border: 1.5px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 4px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #895cf2;
	background-color: transparent;
}

.card .dados-box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.card .dados-responsavel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.circle-avatar {
	width: 52px;
	height: 52px;
	background: #cbd4ed;
	border-radius: 26px;
	margin-right: 12px;
}

.card .dados-responsavel h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
}

.card .check {
	width: 24px;
	height: 24px;
	background-color: #1ebe5e;
	border-radius: 12px;
	position: absolute;
	top: 16px;
	right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Gestor */

.franquia-responsavel .box-tag {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: 126px;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	margin-top: 16px;
}

.franquia-responsavel .box-tag .box-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
}

.franquia-responsavel .box-tag .box-text .tags,
.box-revisao .dados .dados-franquia .box-selected .box-text .tags {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	width: 119px;
	height: 26px;
	background: rgba(184, 154, 253, 0.5);
	border-radius: 8px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #895cf2;
}

.franquia-row h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
	margin-bottom: 8px;
}

.text-span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
	margin-bottom: 24px;
}

/* Styles Documentos */

.franquia-row .box-documentos {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 8px;
	width: 100%;
	margin-bottom: 24px;
}

.franquia-row .franja {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #f59300;
}

.franquia-row .documentos {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
	width: 100%;
	height: 100%;
}

.franquia-row .descricao {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 10px;
	position: relative;
}

.franquia-row .descricao h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #1f2832;
}

.franquia-row .descricao span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: rgba(52, 68, 83, 0.5);
}

.franquia-row .dot {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
}

.franquia-row .dot-orange {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f59300;
	margin-right: 6px;
	margin-top: 5px;
}

.franquia-row .dot span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #f59300;
}

.franquia-row .icon-upload {
	margin-right: 5px;
}

/* Docs Verde */

.franquia-row .franja-verde {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #1ebe5e;
}

.franquia-row .dot-verde {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #1ebe5e;
	margin-right: 6px;
	margin-top: 5px;
}

.franquia-row .dot p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #1ebe5e;
}

.franquia-row .line-docs {
	border: 0.5px solid #cbd4ed;
	width: 100%;
	margin-top: 10px;
}

.franquia-row .arquivos-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 16px;
	width: 100%;
}

.franquia-row .arquivos-box span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

.franquia-row .arquivos-box .arquivos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-top: 26px;
	padding-bottom: 18px;
	padding-left: 10px;
	padding-right: 10px;
}

.franquia-row .arquivos-box .arquivos .nome {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.franquia-row .arquivos-box .arquivos .check-doc {
	height: 20px;
	width: 20px;
	background-color: #1ebe5e;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Docs Vermelho */
.franquia-row .franja-vermelho {
	width: 8px;
	height: 100%;
	border-bottom-left-radius: 8px;
	border-top-left-radius: 8px;
	background-color: #ff4d4f;
}

.franquia-row .dot-red {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	position: absolute;
	right: 5px;
	top: 5px;
}

.franquia-row .dot-vermelho {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #ff4d4f;
	margin-right: 6px;
	margin-top: 5px;
}

.franquia-row .dot-red h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #ff4d4f;
}

.franquia-row .arquivos-box .arquivos .check-doc-red {
	height: 20px;
	width: 20px;
	background-color: #ff4d4f;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
}

.franquia-row .arquivos-box .arquivos .check-upload {
	flex-direction: row;
	display: flex;
	justify-self: flex-end;
	align-items: center;
}

.franquia-row .historico {
	margin-top: 20px;
	margin-bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.franquia-row .historico button {
	background-color: transparent;
}

.franquia-row .historico span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #895cf2;
}

/*Clasificaco */

.row-clasificacao,
.row-revisao {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 24px;
	padding-bottom: 40px;
}

.row-clasificacao .status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 16px 0px 16px;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 8px;
	margin-bottom: 24px;
}

.row-clasificacao .status h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #1f2832;
}

.row-clasificacao .status p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
	margin-bottom: 16px;
}

.row-clasificacao .status .status-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}

.row-clasificacao .status .status-row .check {
	width: 16px;
	height: 16px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	border-radius: 100px;
	margin-right: 7px;
}

.row-clasificacao .status .status-row .check-selected {
	width: 16px;
	height: 16px;
	background: #ffffff;
	border: 1px solid #895cf2;
	box-sizing: border-box;
	border-radius: 100px;
	margin-right: 7px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.row-clasificacao .status .status-row .check-selected .circle {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: #895cf2;
}

.row-clasificacao .status .status-row span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #1f2832;
}

.row-clasificacao .status .botoes {
	margin-bottom: 12px;
}

.row-clasificacao .status .botoes button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 48%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
	position: relative;
}

.row-clasificacao .status .botoes button.selected {
	background: #895cf2;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	border: none;
}

.row-clasificacao .status .botoes button.selected-button {
	background: #895cf2;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	border: none;
	width: 31%;
}

.row-clasificacao .status .botoes button.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 31%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
}

.row-clasificacao .status .botoes button .status-icon {
	position: absolute;
	left: 16px;
	top: 18px;
}

.row-clasificacao .status .box-select,
.box-revisao .dados .box-select {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 12px;
	width: 100%;
	height: auto;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-top: 16px;
	margin-bottom: 16px;
}

.row-clasificacao .status .box-select .info,
.box-revisao .dados .box-select .info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.row-clasificacao .status .box-select .info span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

.row-clasificacao .status .box-select .info select,
.box-revisao .dados .box-select .info select {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #485d73;
	width: 100%;
}

.row-revisao {
	background-color: #ffffff;
	padding-bottom: 5px;
	margin-bottom: 20px;
}

.row-revisao .menu-revisao {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 36px;
}

.row-revisao .menu-revisao li {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-bottom: 1px solid #cbd4ed;
	width: 17%;
	padding-bottom: 8px;
	padding-top: 8px;
}

.row-revisao .box-revisao {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 24px 24px 10px 24px;
	height: auto;
	width: 100%;
}

.box-revisao .dados {
	position: relative;
	width: 100%;
}

.box-revisao .dados h4 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #344453;
	margin-bottom: 23px;
}

.box-revisao .dados .nome {
	margin-bottom: 24px;
}

.box-revisao .dados .nome h5 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: #6a7c96;
	margin-bottom: 0px;
}

.box-revisao .dados .nome span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	line-height: 36px;
	color: #344453;
}

.box-revisao .dados .dados-franquia {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 24px;
}

.box-revisao .dados .dados-franquia .cpf {
	margin-right: 115px;
}

.box-revisao .dados .dados-franquia .cpf h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: rgba(52, 68, 83, 0.5);
}

.box-revisao .dados .dados-franquia .teste h6 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: rgba(52, 68, 83, 0.5);
}

.box-revisao .dados .dados-franquia .cpf span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.box-revisao .dados button {
	position: absolute;
	right: 0px;
	top: 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	width: 73px;
	height: 40px;
	border: 1.5px solid #ebeffc;
	box-sizing: border-box;
	border-radius: 4px;
	margin: 0px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #895cf2;
	background-color: transparent;
}

.row-revisao .line {
	border: 1px solid #ebeffc;
	width: 100%;
	position: relative;
	margin-bottom: 0px;
}

.box-revisao .dados .input-revisao {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.box-revisao .dados .input-revisao .input-franquia {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: 48%;
	padding-left: 12px;
	padding-right: 12px;
}

.box-revisao .dados .input-revisao .input-franquia .box-data span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

.box-revisao .documentos .estado .check-doc {
	height: 16px;
	width: 16px;
	background-color: #1ebe5e;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.box-revisao .documentos .estado .check-doc-red {
	height: 16px;
	width: 16px;
	background-color: #ff4d4f;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.box-revisao .documentos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #ebeffc;
	padding-bottom: 12px;
	padding-top: 12px;
}

.box-revisao .documentos .estado {
	margin-top: 0px;
	max-width: 85%;
	overflow: hidden;
}

.box-revisao .documentos .estado p {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #344453;
}

.box-revisao .documentos .detalhes {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px;
	width: 32px;
	height: 32px;
	background: rgba(203, 212, 237, 0.5);
	border-radius: 16px;
}

/* .botao-voltar-revisao {
	position: absolute;
	width: 105px;
	height: 40px;
	left: 15%;
	top: 17%;
	background: #ebeffc;
	border-radius: 4px;
} */

/*Detalhe Franquia */
.row-revisao .menu-revisao-detalhe {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 36px;
}

.row-revisao .menu-revisao-detalhe li {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-bottom: 1px solid #cbd4ed;
	width: 20%;
	padding-bottom: 8px;
	padding-top: 8px;
}

.row-revisao .menu-revisao-detalhe li.selected {
	border-bottom: 2px solid var(--primary-color);
	color: var(--primary-color);
}

.box-revisao .dados .dados-franquia .cpf span.verde {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #1ebe5e;
}

/* Detalhes Franquia Editar */
.box-revisao .dados .input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 8px 12px;
	width: 100%;
	height: 58px;
	background: #ffffff;
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 10px;
}

.box-revisao .dados .input span {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #344453;
}

.box-revisao .dados .input input,
.box-revisao .dados .dados-franquia-inputs select {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #485d73;
}

.box-revisao .dados .dados-franquia-inputs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.box-revisao .dados .dados-franquia-inputs .input {
	width: 32%;
}

.box-revisao .dados .voltar,
.box-revisao .dados .proximo {
	width: 320px;
}

.franquia-responsavel .box-selected h4,
.box-revisao .dados .dados-franquia .box-selected h4 {
	margin-bottom: 0px;
}

.box-revisao .dados .dados-franquia .box-selected .box-text .tags {
	color: #ffffff;
	justify-content: flex-start;
	width: auto;
}

.box-revisao .dados .dados-franquia .box-selected {
	height: 126px;
	margin-top: 0px;
}

.box-revisao .dados button.novo-usuario {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;
	position: static;
	width: 100%;
	height: 56px;
	background: #ebeffc;
	border-radius: 4px;
	margin-bottom: 24px;
	margin-top: 24px;
}

.box-revisao .dados .busca {
	border: 1px solid rgba(52, 68, 83, 0.05);
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 15px;
}

.box-revisao .dados .status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 16px 0px 16px;
	width: 100%;
	height: auto;
}

.box-revisao .dados .status .status-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}

.box-revisao .dados .status .status-row .check {
	width: 16px;
	height: 16px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	border-radius: 100px;
	margin-right: 7px;
}

.box-revisao .dados .status .status-row .check-selected {
	width: 16px;
	height: 16px;
	background: #ffffff;
	border: 1px solid #895cf2;
	box-sizing: border-box;
	border-radius: 100px;
	margin-right: 7px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.box-revisao .dados .status .status-row .check-selected .circle {
	height: 8px;
	width: 8px;
	border-radius: 8px;
	background-color: #895cf2;
}

.box-revisao .dados .botoes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box-revisao .dados .botoes button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 48%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
	position: relative;
}

.box-revisao .dados .botoes button.selected {
	background: #895cf2;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	border: none;
}

.box-revisao .dados .botoes button.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px;
	width: 31%;
	height: 56px;
	background: #ffffff;
	border: 1px solid #cbd4ed;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: rgba(52, 68, 83, 0.5);
}

.box-revisao .dados .botoes button.selected-button {
	background: #895cf2;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	border: none;
	width: 31%;
}

.box-revisao .dados .botoes button .status-icon {
	position: absolute;
	left: 16px;
	top: 18px;
}
