@font-face {
	font-family: 'Roboto-Black';
	src: url('../../../assets/fonts/Roboto-Black.ttf');
}
@font-face {
	font-family: 'Roboto-Regular';
	src: url('../../../assets/fonts/Roboto-Regular.ttf');
}

.background {
	opacity: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	animation: modal 0.3s forwards;
}
.modal {
	opacity: 0;
	animation: modal 0.3s forwards;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px;
	position: relative;
	width: 410px;
	height: auto;
	background: #ffffff;
	border-radius: 8px;
}

.modal-box-btn {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: flex-end;
	button {
		background: #0c306c;
		color: #ffffff !important;
		width: 80px;
		height: 40px;
		margin: 10px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-family: Roboto-Black, sans-serif;
	}
}
.informative-icon {
	width: 28px;
	height: 26px;
	display: inline-block;
	margin-right: 5px;
	transform: translateY(6px);
}
.modal-box-text {
	height: 50%;
	width: 100%;
	text-align: left;
	padding: 15px 20px;
	h1 {
		font-family: Robot-Black, sans-serif;
		margin-bottom: 0.7rem;
		color: #0c306c;
		display: inline-block;
        font-weight: bold;
	}
	p {
		font-family: Roboto-Regular, sans-serif;
		margin: 0.3rem 0;
		color: #0c306c;
	}
}
@keyframes modal {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
